import styled from '@emotion/styled';
import { Checkbox, CheckboxProps } from 'antd';

export const FullscreenModalBody = styled.div({
	height: 'calc(95vh - 86px)',
	boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.24)',
	display: 'flex',
	flexDirection: 'column',
	margin: '20px -24px',
	alignItems: 'center',
	padding: '0px 32px',
	overflowY: 'auto',
	gap: '20px',
	paddingTop: '20px',
});

export const CheckboxAlignStart = styled(Checkbox)(
	(
		props: CheckboxProps & {
			padding?: string | number;
		}
	) => ({
		display: 'flex',
		alignItems: 'start',
		'& > :nth-child(1)': {
			marginTop: props.padding,
			alignSelf: 'start',
		},
	})
);
