import { useState } from 'react';
import { getDefaultDrawerProps } from '@overlays/getDefaultOverlayOptions';
import { DrawerProps } from 'antd';

export type DrawerState = {
	isDrawerOpen: boolean;
	setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type UseDrawerProvidedProps = DrawerState & { drawerProps: DrawerProps };

export const useDefaultDrawer = ({
	overrideState,
	...configuration
}: DefaultDrawerConfiguration): UseDrawerProvidedProps => {
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
	return {
		isDrawerOpen: overrideState?.isDrawerOpen ?? isDrawerOpen,
		setIsDrawerOpen: overrideState?.setIsDrawerOpen ?? setIsDrawerOpen,
		drawerProps: getDrawerProps({
			state: overrideState
				? { ...overrideState }
				: {
						isDrawerOpen,
						setIsDrawerOpen,
				  },
			...configuration,
		}),
	};
};

export type DefaultDrawerConfiguration = {
	titleText?: string;
	onClose?(): void;
	overrideState?: DrawerState;
};

export type DrawerConfiguration<T = DefaultDrawerConfiguration> = T & {
	state: DrawerState;
};

const getDrawerProps = (
	props: DrawerConfiguration<DefaultDrawerConfiguration>
): DrawerProps => ({
	...getDefaultDrawerProps(props).drawerProps,
	open: props.state.isDrawerOpen,
	onClose: () => {
		props.onClose();
		props.state.setIsDrawerOpen(false);
	},
	footer: null,
});
