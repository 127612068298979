import React from 'react';
import { CheckboxAlignStart, ShareFileSpinner } from '@common';
import { colorPalette } from '@sharefiledev/antd-config';
import { Flex, theme, Typography } from 'antd';
import { generate as suuid } from 'short-uuid';
import { usePackageEditorContext } from '../context/PackageEditorContext';
import { EditableResource, ResourceEditorProps } from '../types/editorTypes';
import { ResourceEditor } from './ResourceEditor';

type RequestListItem = {
	title: string;
	description: string;
};

type EditableRequestListItem = RequestListItem & {
	selected: boolean;
	ref: string;
};

type RequestList = {
	title: string;
	description: string;
	items: RequestListItem[];
};

type RequestListEditorState = {
	title: string;
	description: string;
	items: EditableRequestListItem[];
};

const toRequestListItem = (i: EditableRequestListItem): RequestListItem => {
	// the resource's data block, when rendered to YAML, must only have the `title` and `description` fields - `selected` and `ref` are internal Request List editor state
	const { selected, ref, ...rest } = i;
	return rest;
};

const selectedItems = (i: EditableRequestListItem) => i.selected;

const computeDataBlock = (resourceEditorState: RequestListEditorState): RequestList => {
	return {
		title: resourceEditorState.title,
		description: resourceEditorState.description,
		items: resourceEditorState.items.filter(selectedItems).map(toRequestListItem),
	};
};

export const RequestListEditor = ({ resource }: ResourceEditorProps) => {
	const { token } = theme.useToken();

	const { dispatch, disabled } = usePackageEditorContext();

	const setResourceSelection = (value: boolean) => {
		dispatch({ type: value ? 'select' : 'deselect', resource });
	};

	const toggleItem = (item: EditableRequestListItem) => {
		const updatedResourceEditorState: RequestListEditorState = {
			...resource.resourceEditorState,
			items: resource.resourceEditorState.items.map((i: EditableRequestListItem) => {
				if (i.ref === item.ref) {
					i.selected = !i.selected;
				}
				return i;
			}),
		};

		dispatch({
			type: 'update',
			resource,
			data: computeDataBlock(updatedResourceEditorState),
			resourceEditorState: updatedResourceEditorState,
		});
	};

	const onItemCheckboxClick = (item: EditableRequestListItem) => {
		if (!resource.selected) {
			setResourceSelection(true);
			if (!item.selected) {
				toggleItem(item);
			}
		} else {
			toggleItem(item);
		}
	};

	const setAllItemsSelection = ({ value }: { value: boolean }) => {
		const updatedResourceEditorState: RequestListEditorState = {
			...resource.resourceEditorState,
			items: resource.resourceEditorState.items.map((i: EditableRequestListItem) => {
				i.selected = value;
				return i;
			}),
		};

		dispatch({
			type: 'update',
			resource,
			data: computeDataBlock(updatedResourceEditorState),
			resourceEditorState: updatedResourceEditorState,
		});

		setResourceSelection(value);
	};

	React.useEffect(() => {
		if (!!resource.resourceEditorState) {
			return;
		}
		dispatch({
			type: 'update',
			resource,
			data: resource.data,
			resourceEditorState: {
				title: resource.data.title,
				description: resource.data.description,
				items: resource.data.items.map((item: RequestListItem) => {
					return {
						...item,
						selected: true,
						ref: 'internal_' + suuid(),
					};
				}),
			},
		});
	}, [dispatch, resource]);

	if (!resource.resourceEditorState) {
		return <ShareFileSpinner size={token.sizeSM} />;
	}

	return (
		<Flex vertical>
			<Flex
				gap={8}
				align="center"
				style={{
					paddingBottom: token.size,
					paddingTop: token.size,
					borderBottom: `1px solid ${colorPalette.neutral3}`,
					background: `${colorPalette.neutral2}`,
					paddingInlineStart: token.size,
					paddingInlineEnd: token.size,
				}}
				justify="space-between"
			>
				<CheckboxAlignStart
					disabled={!!disabled}
					padding={token.sizeXXS}
					checked={resource.data.items.length > 0}
					indeterminate={
						0 < resource.data.items.length &&
						resource.data.items.length < resource.resourceEditorState.items.length
					}
					onClick={() => {
						setAllItemsSelection({ value: !(resource.data.items.length > 0) });
					}}
				>
					<Typography.Title level={4} style={{ margin: 0 }}>
						{resource.resourceEditorState?.title}
					</Typography.Title>
				</CheckboxAlignStart>
				<Typography.Title level={5} style={{ margin: 0 }}>
					{resource.data.items.length} / {resource.resourceEditorState?.items.length}{' '}
					items selected
				</Typography.Title>
			</Flex>
			<Flex vertical>
				{resource.resourceEditorState?.items.map((item: EditableRequestListItem) => {
					return (
						<Flex
							vertical
							key={suuid()}
							style={{
								paddingTop: token.sizeXS,
								paddingInlineStart: token.sizeXL + token.sizeXS,
								paddingBottom: token.sizeXS,
								paddingRight: token.size,
								borderBottom: `1px solid ${colorPalette.neutral3}`,
							}}
						>
							<Flex align="start" gap={token.sizeXS}>
								<CheckboxAlignStart
									disabled={!!disabled}
									padding={token.sizeXXS}
									checked={item.selected && resource.selected}
									onClick={() => onItemCheckboxClick(item)}
								>
									<Flex vertical>
										<Typography.Text strong>{item.title}</Typography.Text>
										<Typography.Text
											type="secondary"
											style={{ fontSize: token.fontSizeSM }}
										>
											{item.description}
										</Typography.Text>
									</Flex>
								</CheckboxAlignStart>
							</Flex>
						</Flex>
					);
				})}
				{!!resource.children &&
					resource.children?.map((child: EditableResource) => {
						return <ResourceEditor resource={child} key={child.ref} />;
					})}
			</Flex>
		</Flex>
	);
};
