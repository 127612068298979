/* istanbul ignore file */
import { createScopedInstance, getLanguage } from '@citrite/translate';
import { languages } from '../../locales';

export { getLanguage };

export const { t: tmp } = createScopedInstance({
	resources: languages,
	namespace: 'resourcegen-pilet',
});

export const t: (str: string, opts?: any) => string = tmp;
export const resolveLocaleString = (str: string, opts?: any) => t(str, opts);

type Locales = 'en' | 'es' | 'de' | 'fr' | 'it' | 'ja' | 'nl' | 'pt-BR';
export type LocaleString = Record<Locales, string>;
/**
 * Used to receive correct locale value from a localization string object.
 * Commonly used to evaluate view configuration block/zone parameters
 * Defaults to English
 */
export const getLocaleValue = (localizationObject: LocaleString) => {
	return localizationObject[getLanguage() as Locales] || localizationObject['en'];
};
