import { colorPalette } from '@sharefiledev/antd-config';
import { AiSparkles } from '@sharefiledev/icons';
import { Flex, theme } from 'antd';

type AiSparklesBannerProps = {
	children: React.ReactNode;
};

export const AiSparklesBanner = (props: AiSparklesBannerProps) => {
	const { token } = theme.useToken();
	return (
		<Flex
			vertical
			style={{
				paddingTop: token.sizeSM,
				paddingBottom: token.sizeSM,
				paddingInline: token.size,
				background: colorPalette.pink2,
				borderRadius: token.sizeXXS,
			}}
			gap={token.size}
		>
			<Flex gap={token.size} align="center">
				<AiSparkles color={colorPalette.pink6} size={token.sizeMS} />
				{props.children}
			</Flex>
		</Flex>
	);
};
