import React from 'react';
import { PackageEditorAction, PackageEditorState } from '../types/editorTypes';
import { PackageEditorError } from '../types/errors';

export type PackageEditorContextValue = {
	editorState: PackageEditorState;
	dispatch: React.Dispatch<PackageEditorAction>;
	disabled?: boolean;
};

export const PackageEditorContext = React.createContext<PackageEditorContextValue>(null);

export const usePackageEditorContext = () => {
	const context = React.useContext(PackageEditorContext);
	if (!context) {
		throw new PackageEditorError(
			'usePackageEditorContext must be used within a PackageEditorContext.Provider'
		);
	}
	return context;
};
