import React from 'react';
import { ShareFileSpinner } from '@common/icons';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { DevelopmentPage, Props } from './DevelopmentPage';

export const AsyncDevelopmentPage: React.FC<{ piletApi: PiletApi }> = (props: Props) => (
	<React.Suspense fallback={<ShareFileSpinner />}>
		<DevelopmentPage {...props} />
	</React.Suspense>
);
