import { useMemo } from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Redirect } from 'react-router-dom';
import { checkRolesAndPreferences } from '../utils/checkRolesAndPreferences';
import { RolesAndRequirements } from '../utils/types';

export interface RenderConditionallyProps {
	piletApi: PiletApi;
	children: any;
	requirements: RolesAndRequirements;
	redirectToDashboard?: boolean;
}

export function RenderConditionally(props: Readonly<RenderConditionallyProps>) {
	const isAllowed = useMemo(() => {
		return checkRolesAndPreferences(props.piletApi, props.requirements);
	}, [props.piletApi, props.requirements]);

	if (isAllowed) {
		return <>{props.children}</>;
	}

	if (props.redirectToDashboard) {
		return <Redirect to="/dashboard"></Redirect>;
	}

	return <></>;
}
