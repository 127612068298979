/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */
import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package

const prefix = 'urn';
const owner = 'resourcegen-pilet';
// const modalNamespace = 'sfmodal';

export const resourceGenPiletExtensionSlots = {};

export type GeneratableResourceType = keyof GenerationParamsMap;

export type GenerationParams<
	T extends keyof GenerationParamsMap = GeneratableResourceType
> = GenerationParamsMap[T];
interface GenerationParamsMap {
	'sharefile:requestlist': {
		request_name: string;
		due_date?: string;
		employment_industry?: string;
		employment_role?: string;
		request_purpose: string;
		enable_file_browser?: boolean;
	};
}

export type GenerationFormParams<T extends keyof GenerationFormParamsMap> =
	GenerationFormParamsMap[T];

interface GenerationFormParamsMap {
	'sharefile:requestlist': {
		request_name: string;
		due_date?: string;
		employment_industry_locale_key_or_custom?: string;
		employment_role_locale_key_or_custom?: string;
		remember_industry_role?: boolean;
		request_purpose: string;
		enable_file_browser?: boolean;
	};
}

export type UseResourceGenerationOverlayParams = {
	resourceType: GeneratableResourceType;
	containerRID: string;
	onNavigate?: (resourceId: string) => void;
	generationFormDefaults?: Partial<GenerationFormParams<GeneratableResourceType>>;
};

export type UseResourceGenerationDrawerOverlayParams = UseResourceGenerationOverlayParams;

export const resourceGenPiletOverlays = {
	useResourceGen: `${prefix}:sfdrawer:${owner}:use-resourcegen` as const,
};

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {}

	interface PiralCustomOverlayOptionsMap {
		[resourceGenPiletOverlays.useResourceGen]: UseResourceGenerationDrawerOverlayParams;
	}

	interface PiralCustomOverlayDataMap {}
}
