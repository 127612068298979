import { logError } from '@logger';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
	ConstructTemplateParams,
	CreateRunParams,
	CreateTemplateRunResponse,
	DeleteTemplateParams,
	ExecutePackageParams,
	ExecutePackageResponse,
	GetRunLogsParams,
	GetRunLogsResponse,
	GetRunParams,
	GetTemplateParams,
	GetTemplateRunResponse,
	ListTemplatesParams,
	PackageExecutionStatusParams,
	PackageExecutionStatusResponse,
	TemplateInfo,
} from './types';

export interface PackagingApiClient {
	listTemplates(params?: ListTemplatesParams): Promise<TemplateInfo[]>;
	getTemplate(params: GetTemplateParams): Promise<TemplateInfo>;
	deleteTemplate(params: DeleteTemplateParams): Promise<void>;
	createTemplateRun(params: CreateRunParams): Promise<CreateTemplateRunResponse>;
	getTemplateRun(params: GetRunParams): Promise<GetTemplateRunResponse>;
	constructTemplate(params: ConstructTemplateParams): Promise<TemplateInfo>;
	getTemplateRunLogs(params: GetRunLogsParams): Promise<GetRunLogsResponse>;
	executePackage(params: ExecutePackageParams): Promise<ExecutePackageResponse>;
	getPackageExecutionStatus(
		params: PackageExecutionStatusParams
	): Promise<PackageExecutionStatusResponse>;
}

export interface PackagingClientConfiguration {
	baseUrl: string;
}

export function initializePackagingClient(
	configuration: PackagingClientConfiguration
): PackagingApiClient {
	const sharedConfig: AxiosRequestConfig = {
		baseURL: configuration.baseUrl,
		timeout: 60000,
		headers: {
			'x-bff-csrf': 'true',
		},
	};

	const templatesApi = axios.create(sharedConfig);

	return {
		listTemplates(params?: ListTemplatesParams): Promise<TemplateInfo[]> {
			return apiProxy(async () => {
				return await templatesApi.get('/v2/templates', {
					params: {
						containerRID: params?.parentRID,
						rootType: params?.rootType,
					},
				});
			}, 'listTemplates');
		},
		getTemplate(params: GetTemplateParams): Promise<TemplateInfo> {
			return apiProxy(async () => {
				return await templatesApi.get(`/v2/templates/${encodeURIComponent(params.id)}`);
			}, 'getTemplate');
		},
		deleteTemplate(params: DeleteTemplateParams): Promise<void> {
			return apiProxy(async () => {
				return await templatesApi.delete(
					`/v2/templates/${encodeURIComponent(params.id)}`
				);
			}, 'deleteTemplate');
		},
		createTemplateRun(params: CreateRunParams): Promise<CreateTemplateRunResponse> {
			return apiProxy(async () => {
				return await templatesApi.post(
					`/v2/templates/${encodeURIComponent(params.templateId)}/run`,
					{
						targetRID: params.parentRID,
						args: params.args,
					}
				);
			}, 'createRun');
		},
		getTemplateRun(params: GetRunParams): Promise<GetTemplateRunResponse> {
			return apiProxy(async () => {
				return await templatesApi.get(`/v2/templates/runs/${params.id}`);
			}, 'getRun');
		},
		constructTemplate(params: ConstructTemplateParams): Promise<TemplateInfo> {
			return apiProxy(async () => {
				return await templatesApi.post('/v2/templates/construct', {
					RID: params.RID,
					name: params.packageName,
					description: params.packageDescription,
				});
			}, 'constructTemplate');
		},
		getTemplateRunLogs(params: GetRunLogsParams): Promise<GetRunLogsResponse> {
			return apiProxy(async () => {
				return await templatesApi.get(`/v2/executions/${params.executionId}/logs`);
			}, 'getInstallationLogs');
		},
		executePackage(params: ExecutePackageParams): Promise<ExecutePackageResponse> {
			return apiProxy(async () => {
				return await templatesApi.post('/v2/execute/', {
					targetRID: params.targetRID,
					args: params.args,
					definition: params.definition,
				});
			}, 'executePackage');
		},
		getPackageExecutionStatus(
			params: PackageExecutionStatusParams
		): Promise<PackageExecutionStatusResponse> {
			return apiProxy(async () => {
				return await templatesApi.get(`/v2/executions/${params.executionId}`);
			}, 'getPackageExecutionStatus');
		},
	};
}

async function apiProxy<T>(
	proxyFn: () => Promise<AxiosResponse<T>>,
	proxyLabel: string
): Promise<T> {
	let response: AxiosResponse<T, any>;
	try {
		response = await proxyFn();
	} catch (error) {
		logError(error, {
			customMessage: `Packaging API failure for ${proxyLabel}`,
		});
		throw error;
	}

	return response.data;
}
