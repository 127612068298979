import { resourceGenSdk } from '@data/clients/resourcegen';
import { GenerationParams } from '@sdk';
import { useQuery } from '@tanstack/react-query';

export function useGenerateResource(
	generationRequest: GenerationParams<'sharefile:requestlist'>,
	ac: AbortController = new AbortController()
) {
	const {
		isLoading: loading,
		isError,
		isRefetching: refetching,
		error,
		data,
		refetch,
	} = useQuery(
		['generateResource', generationRequest],
		async () => {
			return await resourceGenSdk.generateResource(
				'sharefile:requestlist',
				generationRequest,
				ac
			);
		},
		{ retry: false, refetchOnWindowFocus: false }
	);
	return {
		yaml: data?.payload,
		generationTaskId: data?.id,
		loading: loading || refetching,
		isError,
		error,
		refetch,
	};
}
