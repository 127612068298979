import { LoadingIconStars, ShareFileSpinner } from '@common/icons';
import { colorPalette } from '@sharefiledev/antd-config';
import { t } from '@utils/localization';
import { Button, Divider, Flex, theme, Typography } from 'antd';
import { AiCaution } from './AiCaution';

interface GenerationProgressBannerProps {
	onCancel: () => void;
}

export function GenerationProgress(props: GenerationProgressBannerProps) {
	const { token } = theme.useToken();
	return (
		<Flex
			justify="center"
			style={{
				paddingTop: token.sizeXS,
				height: '100%',
				width: '100%',
			}}
		>
			<Flex vertical align="center" style={{ width: '100%' }}>
				<Flex vertical align="center" gap={16}>
					<LoadingIconStars />
					<Flex align="center" gap={token.size} style={{ padding: 16 }}>
						<Flex gap={token.size}>
							<ShareFileSpinner size={token.sizeMD} />
							<Typography.Title level={4} style={{ margin: 0 }}>
								{t('use_resourcegen_drawer.sharefile_requestlist.generation_progress')}
							</Typography.Title>
						</Flex>
						<Button
							onClick={props.onCancel}
							type="text"
							size="large"
							shape="round"
							style={{ color: colorPalette.lavender6 }}
						>
							{t('common.stop')}
						</Button>
					</Flex>
				</Flex>
				<Flex vertical align="center" gap={token.size} style={{ width: '100%' }}>
					<Divider style={{ margin: 0, padding: 0 }} />
					<AiCaution />
				</Flex>
			</Flex>
		</Flex>
	);
}
