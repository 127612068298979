import React from 'react';
import { packagingSdk } from '@data/clients/packaging';

type ExecutePackageButtonProps = {
	packageYamlDefinition: string;
	targetRID: string;
	resourceRef: string;
	onSuccess: (rid: string) => void;
	onError: (error: Error) => void;
	onClick?: () => void;
};

export function useExecutePackage(props: ExecutePackageButtonProps) {
	const { packageYamlDefinition, targetRID, resourceRef, onSuccess, onError } = props;
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState<Error | null>(null);

	const executePackage = async () => {
		try {
			props.onClick && props.onClick();
			setLoading(true);
			setError(null);
			const pollingResult = await packagingSdk.executePackage({
				targetRID,
				args: {},
				definition: packageYamlDefinition,
			});
			onSuccess(pollingResult.states[resourceRef].RID);
		} catch (err) {
			onError(err);
			setError(err);
		} finally {
			setLoading(false);
		}
	};

	return {
		loading,
		packageExecutionError: error,
		executePackage,
	};
}
