interface PollParams<T> {
	/**
	 * Function to call repeatedly
	 */
	pollFn: () => Promise<T>;
	/**
	 * Function to validate the result of the pollFn.
	 * Should return:
	 * * `true`, if the result is valid,
	 * * `false`, if the polling should continue, and
	 * * throw an error, if the polling should stop.
	 * @param result The pollFn's promised return type
	 * @returns
	 */
	validateFn: (result: T) => boolean;
	maxAttempts: number;
	delayInMilliSeconds: number;
}

/**
 * Polls a function with a constant backoff delay.
 * @returns The result of the pollFn passed as part of the pollParams
 * @throws PollingLimitExceededError if the maximum number of attempts is reached
 * @throws Error if the validateFn throws an error
 * @example
 * ```ts
 * const result = await pollWithConstantBackOff({
 * 	pollFn: async () => {
 * 		return await this.initializedClient.getStatus(task.task_id);
 * 	},
 * 	validateFn: (statusRecord: StatusRecord) => {
 * 		if (statusRecord.state === StatusState.Failed || statusRecord.state === StatusState.Unknown) {
 * 			throw new Error('Resource generation failed');
 * 		}
 * 		return statusRecord.state === StatusState.Completed;
 * 	},
 * 	delayInMilliSeconds: 1500,
 * 	maxAttempts: 120,
 * });
 * ```
 */
export async function pollWithConstantBackOff<T>(pollParams: PollParams<T>): Promise<T> {
	const { pollFn, validateFn, delayInMilliSeconds, maxAttempts } = pollParams;
	let attempts = 0;

	const executePoll = async (
		resolve: (value: T | PromiseLike<T>) => void,
		reject: (reason?: any) => void
	) => {
		try {
			attempts++;
			const result = await pollFn();
			if (validateFn(result)) {
				return resolve(result);
			}
			retry(resolve, reject);
		} catch (e) {
			return reject(e);
		}
	};

	const retry = (
		resolve: (value: T | PromiseLike<T>) => void,
		reject: (reason?: any) => void
	) => {
		if (maxAttempts && attempts === maxAttempts) {
			throw new PollingLimitExceededError();
		}
		const timeOutInMilliSeconds = delayInMilliSeconds + getRandomInt(100);
		setTimeout(executePoll, timeOutInMilliSeconds, resolve, reject);
	};

	const getRandomInt = (max: number) => Math.floor(Math.random() * max);

	return new Promise<T>(executePoll);
}

export class PollingLimitExceededError extends Error {
	public constructor() {
		super('Max attempts exceeded');
	}
}
