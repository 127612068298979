import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { pollWithConstantBackOff } from '@utils';
import { getApiBaseUrl } from '../../helpers/getApiBaseUrl';
import { initializePackagingClient, PackagingApiClient } from './client';
import {
	ConstructTemplateParams,
	CreateRunParams,
	CreateTemplateRunResponse,
	DeleteTemplateParams,
	ExecutePackageParams,
	GetRunLogsParams,
	GetRunLogsResponse,
	GetRunParams,
	GetTemplateParams,
	GetTemplateRunResponse,
	ListTemplatesParams,
	PackageExecutionStatusResponse,
	PackageExecutionStatusState,
	TemplateInfo,
} from './types';

/**
 * Wraps the Templates v2 Axios SDK (see ./sdk/sdkV2.ts)
 */
export class PackagingSdk {
	private initializedClient: PackagingApiClient;

	public Initialize(client: PackagingApiClient) {
		this.initializedClient = client;
	}

	public listTemplates(params?: ListTemplatesParams): Promise<TemplateInfo[]> {
		return this.initializedClient.listTemplates(params);
	}

	public getTemplate(params: GetTemplateParams): Promise<TemplateInfo> {
		return this.initializedClient.getTemplate(params);
	}

	public createRun(params: CreateRunParams): Promise<CreateTemplateRunResponse> {
		return this.initializedClient.createTemplateRun(params);
	}

	public deleteTemplate(params: DeleteTemplateParams): Promise<void> {
		return this.initializedClient.deleteTemplate(params);
	}

	public getRun(params: GetRunParams): Promise<GetTemplateRunResponse> {
		return this.initializedClient.getTemplateRun(params);
	}

	public constructTemplate(params: ConstructTemplateParams): Promise<TemplateInfo> {
		return this.initializedClient.constructTemplate(params);
	}

	public getRunLogs(params: GetRunLogsParams): Promise<GetRunLogsResponse> {
		return this.initializedClient.getTemplateRunLogs(params);
	}

	/**
	 * Triggers and then polls the status of a ShareFile Package execution
	 * @throws Error when the package execution fails
	 * @returns Execution status payload, when the package execution is completed successfully
	 */
	public async executePackage(
		params: ExecutePackageParams
	): Promise<PackageExecutionStatusResponse> {
		const execution = await this.initializedClient.executePackage(params);
		return await pollWithConstantBackOff<PackageExecutionStatusResponse>({
			pollFn: async () => {
				return await this.initializedClient.getPackageExecutionStatus(execution);
			},
			maxAttempts: 400,
			validateFn: result => {
				if (result.status === PackageExecutionStatusState.Failed) {
					throw new Error('Package execution failed');
				}
				return (
					result?.status !== PackageExecutionStatusState.Running &&
					result?.status !== PackageExecutionStatusState.Requested &&
					result?.status !== PackageExecutionStatusState.AwaitingUpload
				);
			},
			delayInMilliSeconds: 1500,
		});
	}
}

export const packagingSdk = new PackagingSdk();

export function configurePackagingSdk(app: PiletApi) {
	packagingSdk.Initialize(
		initializePackagingClient({
			baseUrl: getApiBaseUrl(app, 'packaging'),
		})
	);
}
