import { t } from '@utils/localization';
import { Typography } from 'antd';

export interface AiCautionProps {
	style?: React.CSSProperties;
}

export function AiCaution(props: AiCautionProps) {
	return (
		<Typography.Text type="secondary" style={props.style}>
			{t('common.ai_caution')}
		</Typography.Text>
	);
}
