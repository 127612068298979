import React from 'react';
import { ShareFileSpinner } from '@common/icons';
import { t } from '@utils';
import { Alert, Flex } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import { ResourceEditor } from './components/ResourceEditor';
import { PackageEditorContext } from './context/PackageEditorContext';
import { EditableResource, PackageEditorProps } from './types/editorTypes';
import { PackageEditorError } from './types/errors';
import { actionHandler } from './utils/actionHandler';

export function PackageEditor(props: PackageEditorProps) {
	const [editorState, dispatch] = React.useReducer(actionHandler, {
		originalYamlPackage: props.packageYamlDefinition,
		loading: true,
		originalPackageEmpty: undefined,
	});

	React.useEffect(() => {
		// when provided Package YAML definition changes, reload the editor
		dispatch({ type: 'refresh', initialYamlPackage: props.packageYamlDefinition });
	}, [props.packageYamlDefinition]);

	React.useEffect(() => {
		// when editor state changes, notify parent component
		props.onUpdate({ editorState });
	}, [editorState, props]);

	return editorState.originalPackageEmpty ? (
		<div>{t('package_editor.errors.no_resources')}</div>
	) : editorState.error ? (
		<props.errorComponent error={editorState.error} />
	) : (
		<PackageEditorContext.Provider
			value={{
				editorState,
				dispatch,
				disabled: props.disabled,
			}}
		>
			<Flex
				vertical
				gap={16}
				style={{
					height: '100%',
				}}
			>
				{editorState.loading && (
					<Flex align="center" justify="center">
						<ShareFileSpinner />
					</Flex>
				)}
				{!editorState.loading &&
					!!editorState.editablePackage &&
					editorState.editablePackage.resources.map((resource: EditableResource) => {
						return (
							<ErrorBoundary
								onError={() => {
									dispatch({
										type: 'error',
										error: new PackageEditorError(
											'Rendering failed - invalid package contents'
										),
									});
								}}
								key={resource.ref}
								fallback={
									<Alert
										message={t('common.oops')}
										description={t('package_editor.errors.err_loading_resource')}
										type="error"
									/>
								}
							>
								<ResourceEditor resource={resource} />
							</ErrorBoundary>
						);
					})}
				{!editorState.loading &&
					(!editorState.editablePackage ||
						editorState.editablePackage.resources.length === 0) && (
						<h1>{t('package_editor.errors.no_resources')}</h1>
					)}
			</Flex>
		</PackageEditorContext.Provider>
	);
}
