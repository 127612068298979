import React from 'react';
import { ResourceGenerationOverlayPage } from '@components/GenerationDrawerContents/drawerContentsTypes';
import {
	GeneratableResourceType,
	GenerationParams,
	UseResourceGenerationOverlayParams,
} from '@sdk';

export type ResourceGenContext = UseResourceGenerationOverlayParams & {
	setPage(page: ResourceGenerationOverlayPage): void;
	page: ResourceGenerationOverlayPage;
	generationRequest: GenerationParams<GeneratableResourceType>;
	setGenerationRequest(data: GenerationParams<GeneratableResourceType>): void;
	onClose(): void;
	resourceType: GeneratableResourceType;
	generationTaskId: string;
	setGenerationTaskId(taskId: string): void;
};

const ResourceGenContext = React.createContext<ResourceGenContext>(undefined);

export const useResourceGenContext = () => React.useContext(ResourceGenContext);

type ResourceGenerationContextProps = UseResourceGenerationOverlayParams & {
	children: React.ReactNode;
	onClose(): void;
	resourceType: GeneratableResourceType;
};

export const ResourceGenContextProvider = (props: ResourceGenerationContextProps) => {
	const [page, setPage] = React.useState<ResourceGenerationOverlayPage>('form');
	const [generationRequest, setGenerationRequest] =
		React.useState<GenerationParams<GeneratableResourceType>>(null);
	const [generationTaskId, setGenerationTaskId] = React.useState<string>(null);
	return (
		<ResourceGenContext.Provider
			value={{
				...props,
				page,
				setPage,
				generationRequest,
				setGenerationRequest,
				generationTaskId,
				setGenerationTaskId,
			}}
		>
			{props.children}
		</ResourceGenContext.Provider>
	);
};
