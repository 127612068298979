import { Binary, Literal, Ref, Template } from '@sharefile/domino-types';
import { PackageSpec } from '@sharefile/packaging-types';
import * as YAML from 'yaml';
import { PackageLoaderError } from '../types/errors';
import validate from './packageSchema.js';

const templateTag = {
	tag: '!template',
	collection: 'map',
	nodeClass: YAML.YAMLMap,
	identify: v => v instanceof Template,
	resolve(map: YAML.YAMLMap) {
		if (map.has('source') && map.has('context')) {
			return new Template(map.get('source') as string, map.get('context') as object);
		} else {
			throw new Error('Invalid !template specified.');
		}
	},
};

const literalTag = {
	tag: '!literal',
	collection: 'map',
	nodeClass: YAML.YAMLMap,
	identify: v => v instanceof Literal,
	resolve(map: YAML.YAMLMap) {
		if (map.has('value')) {
			return new Literal(map.get('value'));
		} else {
			throw new Error('Invalid !literal specified.');
		}
	},
};

const binaryTag = {
	tag: '!binary',
	identify: v => v instanceof Binary,
	resolve(value) {
		return new Binary(Buffer.from(value as string, 'base64'));
	},
	stringify({ value }) {
		const binaryObj = value as Binary;
		return binaryObj.toJSONValue().data;
	},
};

const refTag = {
	tag: '!ref',
	identify: v => v instanceof Ref,
	resolve(value) {
		return new Ref(value as string);
	},
	stringify({ value }) {
		return (value as Ref).toJSONValue().value;
	},
};

const customTags = [
	templateTag as any,
	literalTag as any,
	binaryTag as any,
	refTag as any,
];

export function parseYamlWithCustomTags(source: string) {
	return YAML.parse(source, {
		customTags,
	});
}

export function stringifyYamlWithCustomTags(source: any) {
	return YAML.stringify(source, {
		customTags,
	});
}

export const loadYamlPackage = (yamlPackage: string): PackageSpec => {
	let parsedYamlPackage: any;
	try {
		parsedYamlPackage = parseYamlWithCustomTags(yamlPackage);
	} catch (error) {
		throw new PackageLoaderError('Provided package document could not be parsed as YAML');
	}
	const isValid = validate(parsedYamlPackage);
	if (!isValid) {
		throw new PackageLoaderError(
			'Invalid YAML package definition - did not match Package schema'
		);
	}
	return parsedYamlPackage as PackageSpec;
};
