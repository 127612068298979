import { ResourceEditorProps } from '../types/editorTypes';
import { RequestListEditor } from './RequestListEditor';

export const ResourceEditor = ({ resource }: ResourceEditorProps) => {
	switch (resource.type) {
		case 'sharefile:package':
			return <h2>Package</h2>;
		case 'sharefile:requestlist':
			return <RequestListEditor resource={resource} />;
		default:
			return <></>;
	}
};
