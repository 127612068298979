export enum LifecycleStage {
	active = 0,
	delete_pending = 1,
	draft = 2,
}

export type TemplatePolicySet = {
	CanRead: boolean;
	CanWrite: boolean;
	CanDelete: boolean;
	CanShare: boolean;
};

export type TemplateActionSet = {
	details?: string;
};

/**
 * A template information item.
 * @export
 * @interface TemplateInfo
 */
export type TemplateInfo = {
	id: string;
	RID: string;
	name: string;
	description?: string;
	containerRID: string;
	sourceRID?: string;
	created: string;
	creator: string;
	namespace?: string;
	rootType: string;
	lifecycleStage?: LifecycleStage;
	parameters?: any;
	policies: TemplatePolicySet;
	actions: TemplateActionSet;
	creationErrors?: string[];
	isLegacy?: boolean;
	isShared?: boolean;
};

export type CreateTemplateRunResponse = {
	id: string;
};

export enum RunStatus {
	Pending = 'pending',
	Success = 'success',
	Error = 'error',
}

export type ErrorResponse = { message: string; stack?: any; packageExecutionId?: string };

export type GetTemplateRunResponse = {
	id: string;
	containerRID: string;
	templateId: string;
	creator: string;
	created: string;
	payload: object;
	status: RunStatus;
	error?: Record<string, any> | ErrorResponse;
	expiration?: number;
	resultRID?: string;
	executionIds?: string[];
};

export type GetRunLogsParams = {
	executionId: string;
};

export type GetRunLogsResponse = RunLog[];

export type RunLog = {
	entry: {
		level: string;
		message: string;
		meta?: {
			[name: string]: any;
		};
	};
	timestamp: number;
};

/* Request Parameter types */
export type CreateTemplateParams = {
	definition: string;
};

export type CreateTemplateFromResourceParams = {
	resourceRID: string;
};

export type DeleteTemplateParams = {
	id: string;
};

export type GetTemplateParams = {
	id: string;
};

export type ListTemplatesParams = {
	parentRID?: string;
	rootType?: string;
	searchTerm?: string;
	filter?: (t: TemplateInfo) => boolean;
};

export type UpdateTemplateParams = {
	id: string;
	definition: string;
};

export type CreateRunParams = {
	templateId: string;
	/**
	 * Container under which the resulting resource should get created (generally the account RID)
	 */
	parentRID: string;
	args?: object;
};

export type GetRunParams = {
	id: string;
};

export type ConstructTemplateParams = {
	RID: string;
	packageName: string;
	packageDescription?: string;
};

export type ExecutePackageParams = {
	targetRID: string;
	args: object;
	definition: string;
};

export type ExecutePackageResponse = {
	executionId: string;
};

export type PackageExecutionStatusParams = {
	executionId: string;
};

export enum PackageExecutionStatusState {
	Requested = 'requested',
	Running = 'running',
	Success = 'success',
	Failed = 'failed',
	AwaitingUpload = 'awaiting-upload',
}

export type PackageExecutionStatusResponse = {
	id: string;
	targetRID: string;
	created: string;
	expiration: number;
	status: PackageExecutionStatusState;
	states: Record<string, { RID: string }>;
};
