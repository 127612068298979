import { PiletApi } from '@sharefiledev/sharefile-appshell';
import {
	GeneratableResourceType,
	resourceGenPiletOverlays,
} from '../../sdk/extensionTypes';

export function useResourceGenModal(
	piletApi: PiletApi,
	containerRID: string,
	resourceType: GeneratableResourceType
) {
	return {
		showResourceGenModal: () => {
			piletApi?.showModal?.(resourceGenPiletOverlays.useResourceGen, {
				resourceType,
				containerRID,
			});
		},
	};
}
