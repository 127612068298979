import { withQueryClientProvider } from '@data/helpers/withQueryClientProvider';
import { AppContextProvider, ShareFileContextProvider } from '@utils/context';

const _ExtensionWrapper = ({ app, children }) => {
	return (
		<AppContextProvider value={{ piletApi: app }}>
			<ShareFileContextProvider>{children}</ShareFileContextProvider>
		</AppContextProvider>
	);
};

export const ExtensionWrapper = withQueryClientProvider(_ExtensionWrapper);
