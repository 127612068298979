import UrlPattern from 'url-pattern';

const basePath = '/resourcegen'; // This was '/templates' in the original code
export const BaseRoutePattern = new UrlPattern(basePath);

export const resourceGenPath = basePath;
// export const TemplateListRoutePattern = new UrlPattern(templatesListPath);

// export const templateDetailsPath = templatesListPath + '/:id';
// export const createNewTemplatePath = templatesListPath + '/new';

// export interface SelectedTemplateRouteParams {
//     id: string;
// }

// export const TemplateDetailsRoutePattern = new UrlPattern(templateDetailsPath);
// export const buildTemplateDetailsRoute = (params: SelectedTemplateRouteParams) => {
//     return TemplateDetailsRoutePattern.stringify(params);
// };

// export const runTemplatePath = templateDetailsPath + '/run';
// export const RunTemplateRoutePattern = new UrlPattern(runTemplatePath);
// export const buildRunTemplateRoute = (params: SelectedTemplateRouteParams) => {
//     return RunTemplateRoutePattern.stringify(params);
// };

// export const workflowListPaths = [
//     templatesListPath,
//     templateDetailsPath,
//     createNewTemplatePath,
//     runTemplatePath,
// ];
// export const templateCRUDPaths = [templateDetailsPath, createNewTemplatePath];

export const findMatchingPath = (pathsList: string[], pathname: string) => {
	return pathsList.find(path => {
		return !!new UrlPattern(path).match(pathname);
	});
};
