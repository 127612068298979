import React, { useEffect } from 'react';
import { t } from '@utils/localization';
import { SelectProps } from 'antd';
import Fuse from 'fuse.js';

let timeout: ReturnType<typeof setTimeout> | null;
let currentlyTypedQuery: string;

export type TextOption = {
	label: string;
	value: string;
};

const searchFnFactory = (
	optionsList: TextOption[]
): ((v: string, callback: Function) => void) => {
	const fuse = new Fuse(optionsList, {
		keys: ['label'],
	});
	const search = (query: string, onResult: Function) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		currentlyTypedQuery = query;

		const resolveOptions = () => {
			if (currentlyTypedQuery === query) {
				const results = fuse.search(query).map(item => {
					return item.item;
				});
				if (
					results.find((item: TextOption) =>
						item.label.toLocaleLowerCase().startsWith(query.toLocaleLowerCase())
					) === undefined
				) {
					results.unshift({ label: query, value: 'custom::' + query });
				}
				onResult(results);
			}
		};
		if (query) {
			timeout = setTimeout(resolveOptions, 300);
		} else {
			onResult(optionsList);
		}
	};
	return search;
};

type TextOptionSelectorProps = {
	initialOptions: TextOption[];
	initialSelected?: string;
};

export function useCustomAndSearchableTextSelectionOptions({
	initialOptions,
	initialSelected,
}: TextOptionSelectorProps) {
	const searchFn = React.useMemo(() => {
		return searchFnFactory(initialOptions);
	}, [initialOptions]);
	const [searchValue, setSearchValue] = React.useState<string>();
	const [options, setOptions] = React.useState<SelectProps['options']>(initialOptions);
	const [selected, setSelected] = React.useState<string>(initialSelected || '');
	const [initializing, setInitializing] = React.useState(true);

	const handleSearch = (newValue: string) => {
		setSearchValue(newValue);
		searchFn(newValue, setOptions);
	};

	useEffect(() => {
		if (
			initializing &&
			!!initialSelected &&
			!initialOptions.find(option => option.value === initialSelected)
		) {
			setInitializing(false);
			setOptions([
				{
					label: convertCustomOrLocaleKeyToLabel(initialSelected),
					value: initialSelected,
				},
				...initialOptions,
			]);
			setSelected(initialSelected);
		}
	}, [initialOptions, initialSelected, initializing]);

	useEffect(() => {
		if (
			selected.startsWith('custom::') &&
			options.find(option => option.value === selected) === undefined
		) {
			setOptions([
				{ label: convertCustomOrLocaleKeyToLabel(selected), value: selected },
				...options,
			]);
		}
	}, [selected, options]);

	return {
		options,
		selected,
		handleSearch,
		setSelected,
		setOptions,
		searchValue,
		setSearchValue,
	};
}

export const convertCustomOrLocaleKeyToLabel = (key?: string): string | undefined => {
	if (!!!key) {
		return undefined;
	}
	if (key.startsWith('custom::')) {
		return key.slice('custom::'.length);
	}
	return t(key);
};
