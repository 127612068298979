import { generate as suuid } from 'short-uuid';
import {
	EditableResource,
	PackageEditorAction,
	PackageEditorState,
	Resource,
} from '../types/editorTypes';
import { PackageEditorError, PackageLoaderError } from '../types/errors';
import { loadYamlPackage, stringifyYamlWithCustomTags } from './parser';
import { recomputePackage } from './recomputePackage';

export const actionHandler = (
	state: PackageEditorState,
	action: PackageEditorAction
): PackageEditorState => {
	// child components can only re-render if they detect a change in the object reference. hence, we need to always return a fresh object in this dispatch function. TODO: replace with BFS of the tree to find the resource to update, then return a fresh object with updated resource using spread operator.
	let resource: EditableResource;
	switch (action.type) {
		case 'select':
			resource = retrieveResource(state, action.resource.ref);
			resource.selected = true;
			break;

		case 'deselect':
			resource = retrieveResource(state, action.resource.ref);
			resource.selected = false;
			break;

		case 'refresh':
			return initializePackageEditor({
				originalYamlPackage: action.initialYamlPackage,
				loading: true,
				originalPackageEmpty: undefined,
			});

		case 'update':
			resource = retrieveResource(state, action.resource.ref);
			resource.data = action.data;
			resource.resourceEditorState = action.resourceEditorState;
			break;

		case 'error':
			return { ...state, error: action.error };

		default:
			initializePackageEditor({
				originalYamlPackage: state.originalYamlPackage,
				loading: true,
				originalPackageEmpty: undefined,
			});
			break;
	}
	state = { ...state, computedPackage: recomputePackage(state) };
	state.computedYamlPackage = stringifyYamlWithCustomTags(state.computedPackage);
	return state;
};

export const initializePackageEditor = (
	state: PackageEditorState
): PackageEditorState => {
	try {
		state.originalPackage = loadYamlPackage(state.originalYamlPackage);
	} catch (error) {
		if (error instanceof PackageLoaderError) {
			state.originalPackageEmpty = state.originalYamlPackage.trim() === '';
			state.error = new PackageEditorError('Invalid package definition');
			return state;
		}
		state.originalPackageEmpty = state.originalYamlPackage.trim() === '';
		state.error = error;
		return state;
	}

	if (
		!!!state.originalPackage?.data?.resources ||
		!Array.isArray(state.originalPackage.data.resources)
	) {
		return {
			...state,
			editablePackage: { resources: [] },
			loading: false,
			originalPackageEmpty: true,
		} as PackageEditorState;
	}

	return {
		...state,
		editablePackage: {
			resources: transformToEditable(state.originalPackage.data.resources),
		},
		loading: false,
		originalPackageEmpty:
			state.originalPackage.data.resources.length === 0 ||
			state.originalYamlPackage.trim() === '',
	};
};

export function transformToEditable(resources: Resource[]): EditableResource[] {
	return resources.map((resource: Resource) => {
		return {
			type: resource.type,
			data: resource.data,
			ref: resource.ref || 'gen_' + suuid(),
			children: !!resource.children
				? transformToEditable(resource.children)
				: ([] as EditableResource[]), // todo: replace recursive call with a stack-based iterative approach
			selected: true,
			resourceEditorState: undefined,
		};
	});
}

export function retrieveResource(
	state: PackageEditorState,
	ref: string
): EditableResource {
	const queue = [...state.editablePackage.resources];
	while (queue.length > 0) {
		const resource = queue.shift();
		if (resource.ref === ref) {
			return resource;
		}
		if (!!resource.children) {
			queue.push(...resource.children);
		}
	}
	throw new PackageEditorError('Resource not found');
}
