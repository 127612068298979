import { GeneratableResourceType } from '@sdk/extensionTypes';

const piletPrefix = 'resourcegen_';
const eventPrefix = 'event_';

// TODO: Update the analyticsIds and events to match the actual events and ids used for resource generation
// usage: data-analytics-id={analyticsIds.createButton}
export const analyticsIds = {
	createButton: `${piletPrefix}CreateButton`,
	generateButton: `${piletPrefix}GenerateButton`,
};

export type EventNameMapping = Record<keyof MetadataMapping, string>;

export const eventNameMapping: EventNameMapping = {
	shareTemplateAction: `${piletPrefix}${eventPrefix}ShareTemplateAction`,
	createResourceAction: `${piletPrefix}${eventPrefix}CreateResourceAction`,
	generateResourceAction: `${piletPrefix}${eventPrefix}GenerateResourceAction`,
	generationFeedback: `${piletPrefix}${eventPrefix}GenerationFeedback`,
};

export type MetadataMapping = {
	shareTemplateAction: {
		context: 'DetailsOverlay' | 'TemplateList' | 'TemplateCard';
	};
	createResourceAction: {
		resourceType: GeneratableResourceType;
	};
	generateResourceAction: {
		resourceType: GeneratableResourceType;
	};
	generationFeedback: {
		resourceType: GeneratableResourceType;
		feedback: 'positive' | 'negative';
		generationTaskId: string;
	};
};

export type EventMetadata<T extends keyof MetadataMapping> = MetadataMapping[T];

export type PendoTrackEvent<T extends keyof EventNameMapping> = {
	eventKey: T;
	metadata: EventMetadata<T>;
};

interface Window {
	pendo?: any;
}

export function sendPendoTrackEvent<T extends keyof MetadataMapping>(
	pendoTrackEvent: PendoTrackEvent<T>
) {
	try {
		const pendo = (window as unknown as Window).pendo;
		if (!pendo?.track) {
			return;
		}
		const metadataWithProductName = {
			...pendoTrackEvent.metadata,
			product: 'ShareFile - Resource Generation',
			type: eventNameMapping[pendoTrackEvent.eventKey],
		};
		pendo.track(eventNameMapping[pendoTrackEvent.eventKey], metadataWithProductName);
	} catch {}
}
