import { stringifyYamlWithCustomTags } from './PackageEditor/utils/parser';

export const defaultYaml = stringifyYamlWithCustomTags({
	type: 'sharefile:package',
	data: {
		resources: [
			{
				ref: 'generatedResource',
				type: 'sharefile:requestlist',
				data: {
					title: 'Personal Information and Tax Documents',
					items: [
						{
							title: 'Form W-2',
							description: 'Wage and Tax Statement from all employers',
						},
						{
							title: 'Form 1099',
							description: 'Interest, dividend, and other income statements',
						},
						{
							title: 'Brokerage Statements',
							description: 'Reports of investment transactions and capital gains/losses',
						},
						{
							title: 'Mortgage Interest Statement',
							description: 'Form 1098 from mortgage lender(s)',
						},
						{
							title: 'Property Tax Statements',
							description: 'Proof of property taxes paid for any real estate owned',
						},
						{
							title: 'Charitable Donation Receipts',
							description: 'Records of cash and non-cash contributions',
						},
						{
							title: 'Medical Expense Records',
							description: 'Invoices and receipts for medical expenses',
						},
						{
							title: 'Education Expense Records',
							description: 'Tuition, fees, and student loan interest statements',
						},
						{
							title: 'Business Expense Records',
							description:
								'Records for self-employment or rental property income/expenses',
						},
						{
							title: 'Retirement Contribution Statements',
							description: 'Proof of contributions to qualified retirement plans',
						},
					],
				},
			},
		],
	},
});
