export const LoadingIconStars = () => {
	return (
		<svg
			width="400"
			height="400"
			viewBox="0 0 400 400"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M247.249 207.705L247.824 201.635C247.824 201.635 240.894 197.715 243.199 191.31C244.979 186.355 246.204 181.945 246.204 181.945L253.144 188.29C253.144 188.29 259.159 185.855 259.464 189.945C259.769 194.035 257.799 194.825 257.799 194.825L254.084 195.115L251.854 202.265C251.854 202.265 254.089 203.89 256.529 203.705C258.969 203.52 260.694 202.82 260.694 202.82L262.049 206.34L262.144 216.61L247.704 217.2L247.254 207.705H247.249Z"
				fill="#CFCEFD"
			/>
			<path
				d="M237.718 208.92C237.718 208.92 228.844 203.635 226.534 199.955C222.669 193.795 216.583 178.35 216.583 178.35C216.583 178.35 217.798 175.8 215.853 171.435C213.913 167.065 210.798 157.605 208.693 158.575C206.758 159.465 209.484 165.22 209.484 165.22C209.484 165.22 201.898 167.79 201.958 170.99C202.018 174.19 206.978 181.675 206.978 181.675L213.413 204.645L216.043 208.085L237.173 226.06L237.718 208.925V208.92Z"
				fill="#CFCEFD"
			/>
			<path
				d="M269.179 208.92C269.179 208.92 272.954 211.64 274.344 215.82C275.734 220 287.164 254.305 287.164 254.305C287.164 254.305 290.814 266.705 294.374 269.485C297.934 272.265 300.269 272.87 300.269 272.87C300.269 272.87 302.564 272.31 303.189 274.175L303.814 276.04H288.144L282.559 272.465L280.654 269.485L269.179 243.285C269.179 243.285 270.474 225.625 270.204 221.27C269.934 216.915 269.179 208.92 269.179 208.92Z"
				fill="#CFCEFD"
			/>
			<path
				d="M217.899 260.5L232.209 254.14L266.384 255.365C266.384 255.365 268.744 262.075 267.299 266.7C265.854 271.325 263.484 275.8 263.484 275.8L212.184 276.045L207.074 275.93L201.004 275.675C201.004 275.675 203.199 267.225 206.719 265.845C209.159 264.885 217.899 260.505 217.899 260.505V260.5Z"
				fill="#CFCEFD"
			/>
			<path
				d="M245.089 325.375L252.654 348.285L245.444 349.64L240.694 342.41L238.864 350.875L213.234 355.685L212.524 351.125L229.349 338.46L226.254 328.92L245.089 325.375Z"
				fill="#665AF4"
			/>
			<path
				d="M279.234 323.29L290.854 344.435L284.014 347.085L278.024 340.845L277.769 349.5L253.454 358.91L251.924 354.555L266.144 339.03L261.359 330.215L279.234 323.29Z"
				fill="#665AF4"
			/>
			<path
				d="M268.354 208.92C268.354 208.92 270.848 214.745 270.043 227.565C269.343 238.7 266.634 255.36 266.634 255.36L232.224 253.745C232.224 253.745 236.264 240.335 236.909 230.645C237.554 220.955 237.719 208.92 237.719 208.92L247.249 207.705L247.698 217.2L262.139 216.61L262.039 205.67"
				fill="#A5AEFF"
			/>
			<path d="M202.399 285.695L215.439 320.96H246.044L231.089 285.695" fill="#CFCEFD" />
			<path
				d="M226.474 328.875L244.654 325.375L243.904 321.785L224.134 321.29L226.474 328.875Z"
				fill="#CFCEFD"
			/>
			<path
				d="M256.444 321.29L261.799 330.25L279.234 323.29L278.359 320.96L256.444 321.29Z"
				fill="#CFCEFD"
			/>
			<path d="M237.524 285.695L253.869 320.96H280.994L261.389 285.695" fill="#CFCEFD" />
			<path
				d="M252.184 202.85L253.694 194.83C253.694 194.83 255.519 195.73 257.539 194.83C260.924 193.32 260.344 189.485 259.039 188.205C256.279 185.49 253.043 188.38 253.043 188.38C253.043 188.38 244.314 184.185 246.514 180.1C248.594 176.24 257.364 173.68 264.029 180.1C269.854 185.715 271.584 196.815 262.949 202.01C257.514 205.28 252.184 202.855 252.184 202.855V202.85Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M252.444 202.85L253.954 194.83C253.954 194.83 255.779 195.73 257.799 194.83C261.184 193.32 260.604 189.485 259.299 188.205C256.539 185.49 253.304 188.38 253.304 188.38C253.304 188.38 244.574 184.185 246.774 180.1C248.854 176.24 257.624 173.68 264.289 180.1C270.114 185.715 271.844 196.815 263.209 202.01C257.774 205.28 252.444 202.855 252.444 202.855V202.85Z"
				fill="#020101"
			/>
			<path
				d="M261.334 202.82C261.334 202.82 260.484 204.76 262.969 206.59C263.659 207.1 265.859 207.49 266.509 207.795C268.574 208.76 271.399 208.955 273.934 214.605L290.344 263.875C290.344 263.875 294.149 270.4 297.294 271.98C298.619 272.645 299.844 272.845 301.009 272.96C302.599 273.115 303.804 274.445 303.804 276.04"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M268.354 208.92C268.354 208.92 270.848 214.745 270.043 227.565C269.343 238.7 266.634 255.36 266.634 255.36L232.224 253.745C232.224 253.745 236.264 240.335 236.909 230.645C237.554 220.955 237.719 208.92 237.719 208.92L247.249 207.705L247.698 217.2L262.139 216.61L262.039 205.67"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M247.249 207.705L247.824 201.635C247.824 201.635 240.894 197.715 243.199 191.31C244.979 186.355 246.204 181.945 246.204 181.945"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M235.319 241.365L268.349 242.28C268.349 242.28 275.224 256.48 278.994 264.595C279.949 266.65 280.574 269.65 280.994 270.365C283.559 274.77 288.139 276.04 288.139 276.04"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M237.063 229.09L270.039 228.57"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M266.634 255.36C266.634 255.36 268.619 261.25 267.219 267.065C265.814 272.88 263.309 276.04 263.309 276.04"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M201.399 276.04C201.399 276.04 199.999 269.07 207.799 265.135C211.669 263.18 232.224 253.74 232.224 253.74"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M237.719 208.92C237.719 208.92 228.844 203.635 226.534 199.955C222.669 193.795 216.584 178.35 216.584 178.35C216.584 178.35 217.799 175.8 215.854 171.435C213.914 167.065 210.799 157.605 208.694 158.575C206.759 159.465 211.889 171.45 211.889 171.45"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M209.484 165.22C209.484 165.22 202.094 167.46 201.959 170.99C201.824 174.52 206.979 181.675 206.979 181.675L212.244 201.825C213.034 204.5 214.584 206.89 216.714 208.695L237.179 226.06"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M203.754 168.52L206.139 173.985"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M206.639 167.1L209.024 172.71"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M227.679 276.04C227.679 276.04 230.594 273.89 232.229 270.24C233.864 266.585 234.334 262.935 234.334 262.935L249.229 263.59C249.229 263.59 248.669 268.855 247.544 271.98C246.419 275.11 245.204 276.04 245.204 276.04"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M255.874 276.04C255.874 276.04 257.789 273.06 258.839 269.78C259.889 266.5 259.974 264.14 259.974 264.14L267.649 264.495"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M202.313 285.695L215.354 320.96H245.958L231.004 285.695"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M237.438 285.695L253.784 320.96H280.909L261.303 285.695"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M243.634 320.96L252.654 348.285L245.444 349.64L240.694 342.41L238.864 350.875L213.234 355.685L212.524 351.125L229.349 338.46L223.674 320.96"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M226.254 328.92L245.089 325.375"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M256.264 320.96L266.129 339.055L251.929 354.605L253.469 358.955L277.769 349.5L278.009 340.845L284.014 347.075L290.844 344.415L277.849 320.865"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M261.323 330.25L279.188 323.29"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M157.324 193.83L158.694 189.45C158.694 189.45 153.599 187.12 155.544 181.29C157.209 176.295 158.419 173.705 158.419 173.705L161.004 177.81C161.004 177.81 166.964 175.84 167.459 178.415C167.954 180.99 166.894 183.305 166.894 183.305L162.709 184.07L163.874 190.75L170.444 190.63L171.084 194.19C171.084 194.19 165.314 195.085 162.704 195.075C160.094 195.065 157.329 193.83 157.329 193.83H157.324Z"
				fill="#CFCEFD"
			/>
			<path
				d="M212.184 276.04C212.184 276.04 212.904 274.25 211.049 273.05C209.254 271.885 202.884 271.34 200.174 269.78C195.459 267.06 194.099 258.145 194.099 258.145L187.094 232.575L178.429 238.435L178.934 246.465C178.934 246.465 183.614 256.48 184.864 259.665C186.114 262.85 190.809 273.455 192.189 274.685C193.569 275.915 194.779 276.31 194.779 276.31L212.179 276.045L212.184 276.04Z"
				fill="#CFCEFD"
			/>
			<path
				d="M146.209 233.59L147.874 264.48C147.874 264.48 147.604 268.665 146.019 269.63C144.434 270.595 136.904 273.155 136.904 273.155L136.249 276.04H162.739C162.739 276.04 163.859 274.7 163.349 273.615C162.839 272.53 160.884 269.63 160.884 269.63L159.404 264.895L160.519 236.97L146.209 233.595V233.59Z"
				fill="#CFCEFD"
			/>
			<path
				d="M163.094 332.455L169.329 348.575L132.744 353.59L140.569 335.195"
				fill="#CFCEFD"
			/>
			<path
				d="M190.204 307.36L203.689 318.665L176.684 341.92L173.454 322.195"
				fill="#CFCEFD"
			/>
			<path
				d="M178.309 238.515L188.809 231.415C188.809 231.415 184.644 209.135 181.014 201.68C177.384 194.22 171.119 194.345 171.119 194.345C171.119 194.345 167.854 195.125 163.874 195.125C159.894 195.125 156.799 193.605 156.799 193.605C150.389 193.605 145.154 198.73 145.024 205.14L144.614 213.255L138.944 231.875L133.209 250.31L140.839 255.56L147.934 258.515L146.589 234.015L160.994 237.075L160.224 263.15C160.224 263.15 164.729 264.12 169.964 264.29C175.199 264.46 178.949 262.93 178.949 262.93L178.319 238.515H178.309Z"
				fill="#CFCEFD"
			/>
			<path
				d="M129.754 257.4L136.579 252.92C136.579 252.92 140.829 255.965 142.089 256.485C144.229 257.365 147.694 259.505 147.694 259.505C147.694 259.505 148.954 268.305 145.719 269.8C142.484 271.295 137.869 271.875 136.899 273.16C135.934 274.45 136.064 275.735 136.064 275.735L107.209 276.045C107.209 276.045 106.854 272.715 109.034 271.25C111.209 269.785 129.749 257.405 129.749 257.405L129.754 257.4Z"
				fill="#665AF4"
			/>
			<path
				d="M160.214 263.22C160.214 263.22 159.424 267.455 160.734 269.355C162.044 271.255 161.869 270.44 162.549 271.99C163.229 273.545 163.349 275.86 163.349 275.86H174.799C174.799 275.86 177.674 273.615 177.999 271.66C178.324 269.705 178.119 263.22 178.119 263.22C178.119 263.22 172.889 264.325 169.579 264.28C166.269 264.235 160.214 263.22 160.214 263.22Z"
				fill="#665AF4"
			/>
			<path
				d="M190.724 307.705L168.154 285.695H151.794H107.194C107.194 285.695 107.979 288.94 109.974 293.23C111.964 297.52 131.579 336.29 131.579 336.29L166.824 332L156.189 310.995L170.219 325.375L190.724 307.71V307.705Z"
				fill="#665AF4"
			/>
			<path
				d="M162.704 184.065C162.704 184.065 164.594 184.8 166.344 183.645C168.769 182.045 168.524 178.885 167.159 177.55C164.309 174.77 161.184 177.985 161.184 177.985C161.184 177.985 156.544 174.485 158.744 170.4C160.824 166.54 169.394 163.135 176.384 170.065C181.724 175.36 177.974 190.34 170.924 190.605C166.064 190.79 164.069 190.735 164.069 190.735L162.714 184.06L162.704 184.065Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M162.779 184.215C162.779 184.215 165.024 184.34 166.339 183.65C168.914 182.3 168.274 179.18 166.944 177.71C164.744 175.28 161.224 177.99 161.224 177.99C161.224 177.99 156.584 174.49 158.784 170.405C160.864 166.545 169.434 163.14 176.424 170.07C181.764 175.365 178.014 190.345 170.964 190.61C166.104 190.795 164.109 190.74 164.109 190.74L162.784 184.215H162.779Z"
				fill="#020101"
			/>
			<path
				d="M162.814 208.92L165.275 238.085L144.435 233.17L145.015 205.135C145.15 198.725 150.38 193.6 156.79 193.6C156.79 193.6 159.885 195.12 163.865 195.12C167.845 195.12 171.11 194.34 171.11 194.34C171.11 194.34 177.38 194.22 181.005 201.675C184.635 209.135 188.8 231.41 188.8 231.41L178.3 238.51"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M157.324 193.83L158.694 189.45C158.694 189.45 153.599 187.12 155.544 181.29C157.209 176.295 158.419 173.705 158.419 173.705"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M171.114 194.34L170.438 191.31"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M147.694 258.955C137.794 254.685 133.199 250.315 133.199 250.315L144.854 212.85"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M177.544 208.92L178.944 262.93C178.944 262.93 172.169 265.835 160.219 263.15"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M146.209 233.59L147.859 261.76C147.859 261.76 148.694 268.89 144.434 270.59C141.644 271.705 138.999 272.22 137.504 272.785C136.749 273.075 136.249 273.795 136.249 274.605V276.035"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M160.519 236.965L159.724 265.145C159.724 265.145 159.699 268.5 161.704 270.83C163.469 272.885 163.969 275.2 162.434 276.305"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M174.435 276.04C174.435 276.04 177.41 274.825 178.12 271.14C178.83 267.455 178.12 263.215 178.12 263.215"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M178.429 243.275L188.404 268.66C188.404 268.66 189.864 272.1 190.939 273.33C192.804 275.46 193.769 276.04 193.769 276.04"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M187.099 232.57L194.104 258.14C194.104 258.14 195.463 267.055 200.178 269.775C202.888 271.34 209.258 271.88 211.053 273.045C212.908 274.25 212.188 276.035 212.188 276.035"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M150.159 242.08C150.159 242.08 151.504 243.35 152.974 243.615C154.939 243.975 156.589 243.03 156.589 243.03"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M107.214 276.04C107.214 276.04 106.754 272.845 110.279 270.09C113.804 267.335 136.249 252.695 136.249 252.695"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M107.194 285.695C107.194 285.695 107.979 288.94 109.974 293.23C111.964 297.52 131.579 336.29 131.579 336.29L166.824 332L143.379 285.695"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M156.185 310.995L170.215 325.375L190.725 307.705L168.15 285.695"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M163.094 332.455L169.329 348.575L132.744 353.59L140.569 335.195"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M190.204 307.36L203.689 318.665L176.684 341.92L173.454 322.195"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M307.265 347.815V285.695H298.135V347.815L293.13 358.08H312.27L307.265 347.815Z"
				fill="#CFCEFD"
			/>
			<path
				d="M100.35 347.815V285.695H91.2151V347.815L86.21 358.08H105.355L100.35 347.815Z"
				fill="#CFCEFD"
			/>
			<path d="M316.82 276.04H81.6602V285.695H316.82V276.04Z" fill="#CFCEFD" />
			<path
				d="M316.82 276.04H81.6602V285.695H316.82V276.04Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M100.35 285.695H91.2148V347.81H100.35V285.695Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M105.355 358.08H86.21L91.2151 347.815H100.35L105.355 358.08Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M307.27 285.695H298.135V347.81H307.27V285.695Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M312.27 358.08H293.13L298.135 347.815H307.265L312.27 358.08Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M187.52 108.27C198.011 108.27 206.515 99.7657 206.515 89.2751C206.515 78.7844 198.011 70.28 187.52 70.28C177.03 70.28 168.525 78.7844 168.525 89.2751C168.525 99.7657 177.03 108.27 187.52 108.27Z"
				fill="white"
			/>
			<path
				d="M187.52 108.27C198.011 108.27 206.515 99.7657 206.515 89.2751C206.515 78.7844 198.011 70.28 187.52 70.28C177.03 70.28 168.525 78.7844 168.525 89.2751C168.525 99.7657 177.03 108.27 187.52 108.27Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M178.786 90.485L185.146 96.845L196.256 82.6801"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path d="M313.314 84.9451L301.35 105.67H325.28L313.314 84.9451Z" fill="white" />
			<path
				d="M313.314 84.9451L301.35 105.67H325.28L313.314 84.9451Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path d="M261.865 54.3451H238.305V77.9051H261.865V54.3451Z" fill="white" />
			<path
				d="M261.865 54.3451H238.305V77.9051H261.865V54.3451Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M140.935 116.75H126.145V143.35H149.705V126.67L140.935 116.75Z"
				fill="white"
			/>
			<path
				d="M149.704 143.35H126.149V116.75H140.934V126.67H149.704V143.35Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M137.93 116.75H140.935L149.705 126.67V131.315"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path d="M147.374 42.8551H117.484V63.5051H147.374V42.8551Z" fill="white" />
			<path
				d="M132.429 53.8601L147.374 42.8551H117.484L132.429 53.8601Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M147.374 42.8551H117.484V63.5051H147.374V42.8551Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M87.0139 102.06C93.8043 102.06 99.3088 96.5554 99.3088 89.7651C99.3088 82.9748 93.8043 77.4701 87.0139 77.4701C80.2236 77.4701 74.7188 82.9748 74.7188 89.7651C74.7188 96.5554 80.2236 102.06 87.0139 102.06Z"
				fill="white"
			/>
			<path
				d="M87.0139 102.06C93.8043 102.06 99.3088 96.5554 99.3088 89.7651C99.3088 82.9748 93.8043 77.4701 87.0139 77.4701C80.2236 77.4701 74.7188 82.9748 74.7188 89.7651C74.7188 96.5554 80.2236 102.06 87.0139 102.06Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M296.639 41.05H316.833"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M296.639 48.24H316.833"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M296.639 55.4351H316.833"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
			<path
				d="M243.3 140.375H278.715V120.41H258.565V118.015C258.565 117.225 257.925 116.58 257.13 116.58H244.73C243.94 116.58 243.295 117.22 243.295 118.015V140.375H243.3Z"
				fill="white"
			/>
			<path
				d="M243.3 140.375H278.715V120.41H258.565V118.015C258.565 117.225 257.925 116.58 257.13 116.58H244.73C243.94 116.58 243.295 117.22 243.295 118.015V140.375H243.3Z"
				stroke="#020101"
				strokeWidth="2"
				strokeLinejoin="bevel"
			/>
		</svg>
	);
};
