import React from 'react';
import { ShareFileSpinner } from '@common';
import { OverlayModalComponentProps } from '@sharefiledev/sharefile-appshell';
import { resourceGenPiletOverlays } from '../sdk';

export * from './getDefaultOverlayOptions';

const LazyUseResourceGenerationOverlayContent = React.lazy(
	() => import('./UseResourceGenerationOverlayContent')
);

export type UseResourceGenerationOverlayProps = OverlayModalComponentProps<
	typeof resourceGenPiletOverlays.useResourceGen
>;

export const UseResourceGenerationOverlay: React.FC<
	UseResourceGenerationOverlayProps
> = props => (
	<React.Suspense fallback={<ShareFileSpinner />}>
		<LazyUseResourceGenerationOverlayContent {...props} />
	</React.Suspense>
);
