/* eslint-disable prefer-const */
/* eslint-disable no-var */
'use strict';
module.exports = validate20;
module.exports.default = validate20;
const schema22 = {
	$ref: '#/definitions/PackageSpec',
	$schema: 'http://json-schema.org/draft-07/schema#',
	definitions: {
		PackageSpec: {
			additionalProperties: false,
			properties: {
				children: {
					description: 'Any children resources (optional)',
					items: { $ref: '#/definitions/ResourceSpec' },
					type: 'array',
				},
				data: {
					additionalProperties: false,
					properties: {
						resources: {
							description:
								'The entities that will be created / updated as a result of executing this package',
							items: { $ref: '#/definitions/ResourceSpec' },
							type: 'array',
						},
					},
					required: ['resources'],
					type: 'object',
				},
				ref: {
					description:
						'Reference identifier for the resource.  Must be unique within a single package spec',
					pattern: '^[a-zA-Z_]\\w*$',
					type: 'string',
				},
				type: {
					description: 'Resource type in "<system_identifier>:<resource_type>" format',
					pattern: '^[a-z0-9_]+:[a-z0-9_]+$',
					type: 'string',
				},
			},
			required: ['data', 'type'],
			type: 'object',
		},
		ResourceSpec: {
			additionalProperties: false,
			properties: {
				children: {
					description: 'Any children resources (optional)',
					items: { $ref: '#/definitions/ResourceSpec' },
					type: 'array',
				},
				data: { description: 'Resource metadata', type: 'object' },
				ref: {
					description:
						'Reference identifier for the resource.  Must be unique within a single package spec',
					pattern: '^[a-zA-Z_]\\w*$',
					type: 'string',
				},
				type: {
					description: 'Resource type in "<system_identifier>:<resource_type>" format',
					pattern: '^[a-z0-9_]+:[a-z0-9_]+$',
					type: 'string',
				},
			},
			required: ['type', 'data'],
			type: 'object',
		},
	},
};
const schema23 = {
	additionalProperties: false,
	properties: {
		children: {
			description: 'Any children resources (optional)',
			items: { $ref: '#/definitions/ResourceSpec' },
			type: 'array',
		},
		data: {
			additionalProperties: false,
			properties: {
				resources: {
					description:
						'The entities that will be created / updated as a result of executing this package',
					items: { $ref: '#/definitions/ResourceSpec' },
					type: 'array',
				},
			},
			required: ['resources'],
			type: 'object',
		},
		ref: {
			description:
				'Reference identifier for the resource.  Must be unique within a single package spec',
			pattern: '^[a-zA-Z_]\\w*$',
			type: 'string',
		},
		type: {
			description: 'Resource type in "<system_identifier>:<resource_type>" format',
			pattern: '^[a-z0-9_]+:[a-z0-9_]+$',
			type: 'string',
		},
	},
	required: ['data', 'type'],
	type: 'object',
};
const schema24 = {
	additionalProperties: false,
	properties: {
		children: {
			description: 'Any children resources (optional)',
			items: { $ref: '#/definitions/ResourceSpec' },
			type: 'array',
		},
		data: { description: 'Resource metadata', type: 'object' },
		ref: {
			description:
				'Reference identifier for the resource.  Must be unique within a single package spec',
			pattern: '^[a-zA-Z_]\\w*$',
			type: 'string',
		},
		type: {
			description: 'Resource type in "<system_identifier>:<resource_type>" format',
			pattern: '^[a-z0-9_]+:[a-z0-9_]+$',
			type: 'string',
		},
	},
	required: ['type', 'data'],
	type: 'object',
};
const wrapper0 = { validate: validate22 };
const pattern0 = new RegExp('^[a-zA-Z_]\\w*$', 'u');
const pattern1 = new RegExp('^[a-z0-9_]+:[a-z0-9_]+$', 'u');
function validate22(
	data,
	{ instancePath = '', parentData, parentDataProperty, rootData = data } = {}
) {
	let vErrors = null;
	let errors = 0;
	if (errors === 0) {
		if (data && typeof data == 'object' && !Array.isArray(data)) {
			let missing0;
			if (
				(data.type === undefined && (missing0 = 'type')) ||
				(data.data === undefined && (missing0 = 'data'))
			) {
				validate22.errors = [
					{
						instancePath,
						schemaPath: '#/required',
						keyword: 'required',
						params: { missingProperty: missing0 },
						message: "must have required property '" + missing0 + "'",
					},
				];
				return false;
			} else {
				const _errs1 = errors;
				for (const key0 in data) {
					if (
						!(key0 === 'children' || key0 === 'data' || key0 === 'ref' || key0 === 'type')
					) {
						validate22.errors = [
							{
								instancePath,
								schemaPath: '#/additionalProperties',
								keyword: 'additionalProperties',
								params: { additionalProperty: key0 },
								message: 'must NOT have additional properties',
							},
						];
						return false;
						break;
					}
				}
				if (_errs1 === errors) {
					if (data.children !== undefined) {
						let data0 = data.children;
						const _errs2 = errors;
						if (errors === _errs2) {
							if (Array.isArray(data0)) {
								var valid1 = true;
								const len0 = data0.length;
								for (let i0 = 0; i0 < len0; i0++) {
									const _errs4 = errors;
									if (
										!wrapper0.validate(data0[i0], {
											instancePath: instancePath + '/children/' + i0,
											parentData: data0,
											parentDataProperty: i0,
											rootData,
										})
									) {
										vErrors =
											vErrors === null
												? wrapper0.validate.errors
												: vErrors.concat(wrapper0.validate.errors);
										errors = vErrors.length;
									}
									var valid1 = _errs4 === errors;
									if (!valid1) {
										break;
									}
								}
							} else {
								validate22.errors = [
									{
										instancePath: instancePath + '/children',
										schemaPath: '#/properties/children/type',
										keyword: 'type',
										params: { type: 'array' },
										message: 'must be array',
									},
								];
								return false;
							}
						}
						var valid0 = _errs2 === errors;
					} else {
						var valid0 = true;
					}
					if (valid0) {
						if (data.data !== undefined) {
							let data2 = data.data;
							const _errs5 = errors;
							if (!(data2 && typeof data2 == 'object' && !Array.isArray(data2))) {
								validate22.errors = [
									{
										instancePath: instancePath + '/data',
										schemaPath: '#/properties/data/type',
										keyword: 'type',
										params: { type: 'object' },
										message: 'must be object',
									},
								];
								return false;
							}
							var valid0 = _errs5 === errors;
						} else {
							var valid0 = true;
						}
						if (valid0) {
							if (data.ref !== undefined) {
								let data3 = data.ref;
								const _errs7 = errors;
								if (errors === _errs7) {
									if (typeof data3 === 'string') {
										if (!pattern0.test(data3)) {
											validate22.errors = [
												{
													instancePath: instancePath + '/ref',
													schemaPath: '#/properties/ref/pattern',
													keyword: 'pattern',
													params: { pattern: '^[a-zA-Z_]\\w*$' },
													message: 'must match pattern "' + '^[a-zA-Z_]\\w*$' + '"',
												},
											];
											return false;
										}
									} else {
										validate22.errors = [
											{
												instancePath: instancePath + '/ref',
												schemaPath: '#/properties/ref/type',
												keyword: 'type',
												params: { type: 'string' },
												message: 'must be string',
											},
										];
										return false;
									}
								}
								var valid0 = _errs7 === errors;
							} else {
								var valid0 = true;
							}
							if (valid0) {
								if (data.type !== undefined) {
									let data4 = data.type;
									const _errs9 = errors;
									if (errors === _errs9) {
										if (typeof data4 === 'string') {
											if (!pattern1.test(data4)) {
												validate22.errors = [
													{
														instancePath: instancePath + '/type',
														schemaPath: '#/properties/type/pattern',
														keyword: 'pattern',
														params: { pattern: '^[a-z0-9_]+:[a-z0-9_]+$' },
														message:
															'must match pattern "' + '^[a-z0-9_]+:[a-z0-9_]+$' + '"',
													},
												];
												return false;
											}
										} else {
											validate22.errors = [
												{
													instancePath: instancePath + '/type',
													schemaPath: '#/properties/type/type',
													keyword: 'type',
													params: { type: 'string' },
													message: 'must be string',
												},
											];
											return false;
										}
									}
									var valid0 = _errs9 === errors;
								} else {
									var valid0 = true;
								}
							}
						}
					}
				}
			}
		} else {
			validate22.errors = [
				{
					instancePath,
					schemaPath: '#/type',
					keyword: 'type',
					params: { type: 'object' },
					message: 'must be object',
				},
			];
			return false;
		}
	}
	validate22.errors = vErrors;
	return errors === 0;
}
function validate21(
	data,
	{ instancePath = '', parentData, parentDataProperty, rootData = data } = {}
) {
	let vErrors = null;
	let errors = 0;
	if (errors === 0) {
		if (data && typeof data == 'object' && !Array.isArray(data)) {
			let missing0;
			if (
				(data.data === undefined && (missing0 = 'data')) ||
				(data.type === undefined && (missing0 = 'type'))
			) {
				validate21.errors = [
					{
						instancePath,
						schemaPath: '#/required',
						keyword: 'required',
						params: { missingProperty: missing0 },
						message: "must have required property '" + missing0 + "'",
					},
				];
				return false;
			} else {
				const _errs1 = errors;
				for (const key0 in data) {
					if (
						!(key0 === 'children' || key0 === 'data' || key0 === 'ref' || key0 === 'type')
					) {
						validate21.errors = [
							{
								instancePath,
								schemaPath: '#/additionalProperties',
								keyword: 'additionalProperties',
								params: { additionalProperty: key0 },
								message: 'must NOT have additional properties',
							},
						];
						return false;
						break;
					}
				}
				if (_errs1 === errors) {
					if (data.children !== undefined) {
						let data0 = data.children;
						const _errs2 = errors;
						if (errors === _errs2) {
							if (Array.isArray(data0)) {
								var valid1 = true;
								const len0 = data0.length;
								for (let i0 = 0; i0 < len0; i0++) {
									const _errs4 = errors;
									if (
										!validate22(data0[i0], {
											instancePath: instancePath + '/children/' + i0,
											parentData: data0,
											parentDataProperty: i0,
											rootData,
										})
									) {
										vErrors =
											vErrors === null
												? validate22.errors
												: vErrors.concat(validate22.errors);
										errors = vErrors.length;
									}
									var valid1 = _errs4 === errors;
									if (!valid1) {
										break;
									}
								}
							} else {
								validate21.errors = [
									{
										instancePath: instancePath + '/children',
										schemaPath: '#/properties/children/type',
										keyword: 'type',
										params: { type: 'array' },
										message: 'must be array',
									},
								];
								return false;
							}
						}
						var valid0 = _errs2 === errors;
					} else {
						var valid0 = true;
					}
					if (valid0) {
						if (data.data !== undefined) {
							let data2 = data.data;
							const _errs5 = errors;
							if (errors === _errs5) {
								if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
									let missing1;
									if (data2.resources === undefined && (missing1 = 'resources')) {
										validate21.errors = [
											{
												instancePath: instancePath + '/data',
												schemaPath: '#/properties/data/required',
												keyword: 'required',
												params: { missingProperty: missing1 },
												message: "must have required property '" + missing1 + "'",
											},
										];
										return false;
									} else {
										const _errs7 = errors;
										for (const key1 in data2) {
											if (!(key1 === 'resources')) {
												validate21.errors = [
													{
														instancePath: instancePath + '/data',
														schemaPath: '#/properties/data/additionalProperties',
														keyword: 'additionalProperties',
														params: { additionalProperty: key1 },
														message: 'must NOT have additional properties',
													},
												];
												return false;
												break;
											}
										}
										if (_errs7 === errors) {
											if (data2.resources !== undefined) {
												let data3 = data2.resources;
												const _errs8 = errors;
												if (errors === _errs8) {
													if (Array.isArray(data3)) {
														var valid3 = true;
														const len1 = data3.length;
														for (let i1 = 0; i1 < len1; i1++) {
															const _errs10 = errors;
															if (
																!validate22(data3[i1], {
																	instancePath: instancePath + '/data/resources/' + i1,
																	parentData: data3,
																	parentDataProperty: i1,
																	rootData,
																})
															) {
																vErrors =
																	vErrors === null
																		? validate22.errors
																		: vErrors.concat(validate22.errors);
																errors = vErrors.length;
															}
															var valid3 = _errs10 === errors;
															if (!valid3) {
																break;
															}
														}
													} else {
														validate21.errors = [
															{
																instancePath: instancePath + '/data/resources',
																schemaPath: '#/properties/data/properties/resources/type',
																keyword: 'type',
																params: { type: 'array' },
																message: 'must be array',
															},
														];
														return false;
													}
												}
											}
										}
									}
								} else {
									validate21.errors = [
										{
											instancePath: instancePath + '/data',
											schemaPath: '#/properties/data/type',
											keyword: 'type',
											params: { type: 'object' },
											message: 'must be object',
										},
									];
									return false;
								}
							}
							var valid0 = _errs5 === errors;
						} else {
							var valid0 = true;
						}
						if (valid0) {
							if (data.ref !== undefined) {
								let data5 = data.ref;
								const _errs11 = errors;
								if (errors === _errs11) {
									if (typeof data5 === 'string') {
										if (!pattern0.test(data5)) {
											validate21.errors = [
												{
													instancePath: instancePath + '/ref',
													schemaPath: '#/properties/ref/pattern',
													keyword: 'pattern',
													params: { pattern: '^[a-zA-Z_]\\w*$' },
													message: 'must match pattern "' + '^[a-zA-Z_]\\w*$' + '"',
												},
											];
											return false;
										}
									} else {
										validate21.errors = [
											{
												instancePath: instancePath + '/ref',
												schemaPath: '#/properties/ref/type',
												keyword: 'type',
												params: { type: 'string' },
												message: 'must be string',
											},
										];
										return false;
									}
								}
								var valid0 = _errs11 === errors;
							} else {
								var valid0 = true;
							}
							if (valid0) {
								if (data.type !== undefined) {
									let data6 = data.type;
									const _errs13 = errors;
									if (errors === _errs13) {
										if (typeof data6 === 'string') {
											if (!pattern1.test(data6)) {
												validate21.errors = [
													{
														instancePath: instancePath + '/type',
														schemaPath: '#/properties/type/pattern',
														keyword: 'pattern',
														params: { pattern: '^[a-z0-9_]+:[a-z0-9_]+$' },
														message:
															'must match pattern "' + '^[a-z0-9_]+:[a-z0-9_]+$' + '"',
													},
												];
												return false;
											}
										} else {
											validate21.errors = [
												{
													instancePath: instancePath + '/type',
													schemaPath: '#/properties/type/type',
													keyword: 'type',
													params: { type: 'string' },
													message: 'must be string',
												},
											];
											return false;
										}
									}
									var valid0 = _errs13 === errors;
								} else {
									var valid0 = true;
								}
							}
						}
					}
				}
			}
		} else {
			validate21.errors = [
				{
					instancePath,
					schemaPath: '#/type',
					keyword: 'type',
					params: { type: 'object' },
					message: 'must be object',
				},
			];
			return false;
		}
	}
	validate21.errors = vErrors;
	return errors === 0;
}
function validate20(
	data,
	{ instancePath = '', parentData, parentDataProperty, rootData = data } = {}
) {
	let vErrors = null;
	let errors = 0;
	if (!validate21(data, { instancePath, parentData, parentDataProperty, rootData })) {
		vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
		errors = vErrors.length;
	}
	validate20.errors = vErrors;
	return errors === 0;
}
