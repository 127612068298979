import { TextOption } from '@common/hooks/useCustomAndSearchableTextSelectionOptions';
import { t } from '@utils/localization';
import { industryLocaleKeys } from './industryLocaleKeys';
export const employmentRoles: (industryKey?: string) => TextOption[] = key => {
	if (!!key && !!industryLocaleKeys.find(x => x.industry_key === key)) {
		return industryLocaleKeys
			.find(x => x.industry_key === key)
			.roles_keys.map(roleKey => {
				return {
					value: roleKey,
					label: t(roleKey),
				};
			});
	} else {
		return industryLocaleKeys
			.reduce((acc, industry) => {
				industry.roles_keys.forEach(roleKey => {
					acc.push({
						value: roleKey,
						label: t(roleKey),
					});
				});
				return acc;
			}, [])
			.sort((a, b) => a.label.localeCompare(b.label));
	}
};
