import { useEffect, useState } from 'react';
import { GeneratableResourceType } from '@sdk/extensionTypes';
import {
	ThumbsDown,
	ThumbsDownFilled,
	ThumbsUp,
	ThumbsUpFilled,
} from '@sharefiledev/icons';
import { sendPendoTrackEvent } from '@tracking/pendo';
import { useResourceGenContext } from '@utils/context/useResourceGenerationContext';
import { t } from '@utils/localization';
import { Button, Flex, theme, Typography } from 'antd';

interface UserFeedbackProps {
	resourceType: GeneratableResourceType;
	onBack: () => void;
}

export function UserFeedback(props: UserFeedbackProps) {
	const { token } = theme.useToken();
	const { generationTaskId } = useResourceGenContext();
	const [feedbackGiven, setFeedbackGiven] = useState(false);
	const [feedback, setFeedback] = useState<'positive' | 'negative' | null>(null);

	useEffect(() => {
		setFeedbackGiven(false);
	}, [generationTaskId]);

	return (
		<Flex gap={24} align="center">
			<Flex gap={8} align="center">
				<Typography.Text>{t('common.was_this_helpful')}</Typography.Text>
				<Button
					type="text"
					onClick={() => {
						// TODO: change icon to filled and disable once clicked
						if (!feedbackGiven || feedback !== 'positive') {
							setFeedback('positive');
							setFeedbackGiven(true);
							sendPendoTrackEvent({
								eventKey: 'generationFeedback',
								metadata: {
									resourceType: props.resourceType,
									feedback: 'positive',
									generationTaskId,
								},
							});
						}
					}}
					icon={
						feedbackGiven && feedback === 'positive' ? (
							<ThumbsUpFilled color={token.colorPrimary} />
						) : (
							<ThumbsUp color={token.colorPrimary} />
						)
					}
				/>
				<Button
					type="text"
					onClick={() => {
						if (!feedbackGiven || feedback !== 'negative') {
							setFeedback('negative');
							setFeedbackGiven(true);
							sendPendoTrackEvent({
								eventKey: 'generationFeedback',
								metadata: {
									resourceType: props.resourceType,
									feedback: 'negative',
									generationTaskId,
								},
							});
						}
					}}
					icon={
						feedbackGiven && feedback === 'negative' ? (
							<ThumbsDownFilled color={token.colorPrimary} />
						) : (
							<ThumbsDown color={token.colorPrimary} />
						)
					}
				/>
			</Flex>
			<Button type="link" onClick={props.onBack}>
				{t('common.start_over')}
			</Button>
		</Flex>
	);
}
