import { AccountPreferences } from '@citrite/sf-api';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { logError } from '../logger';
import { checkASTs } from './asts';
import { checkFeatureFlags } from './featureFlags';
import { checkRoles } from './roles';
import { RolesAndRequirements } from './types';

export function checkRolesAndPreferences(app: PiletApi, options: RolesAndRequirements) {
	try {
		const userContext = app.sf.piletUserContext.get();
		const roles = userContext.user.Roles;
		const preferences = userContext.account.Preferences ?? ({} as AccountPreferences);
		return (
			checkASTs(preferences, options.requiredASTs) &&
			checkFeatureFlags(options.requiredFeatureFlags) &&
			checkRoles(roles, options.requiredRoles)
		);
	} catch (err) {
		logError(err, {
			customMessage: 'Failed to perform user role and provisioning check',
		});
		return false;
	}
}
