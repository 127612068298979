import { useResourceGenContext } from '@utils/context/useResourceGenerationContext';
import { FormPage } from './FormPage';
import { ReviewPage } from './ReviewPage';

export const ResourceGenerationContents = () => {
	const { page } = useResourceGenContext();

	return (
		<>
			{page === 'form' && <FormPage />}
			{page === 'review' && <ReviewPage />}
		</>
	);
};
