import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

export function withQueryClientProvider<T>(Component: React.ComponentType<T>) {
	class WithQueryClientProvider extends React.Component<T> {
		public render() {
			return (
				<QueryClientProvider client={queryClient} contextSharing>
					<Component {...this.props} />
				</QueryClientProvider>
			);
		}
	}
	return WithQueryClientProvider as React.ComponentType<T>;
}
