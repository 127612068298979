import { SfIcon } from '@sharefiledev/icons';
import { Spin } from 'antd';

export function ShareFileSpinner(props: {
	'data-testid'?: string;
	size?: number;
	color?: string;
}) {
	return (
		<div>
			<Spin
				data-testid={props['data-testid']}
				indicator={
					<SfIcon
						size={props.size}
						color={props.color}
						className="loading-spinner-antd"
						data-testid="loading-spinner-antd"
					/>
				}
			/>
		</div>
	);
}
