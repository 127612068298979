import {
	GenerationDeclinedError,
	GenerationRateLimitError,
} from '@data/clients/resourcegen/errors';
import { WarningOctagonFilled } from '@sharefiledev/icons';
import { t } from '@utils/localization';
import { Alert, Button, Space } from 'antd';

export function GenerationErrorAlert({
	generationError,
	setIsInvalidGeneratedContent,
	retryGeneration,
}: {
	generationError: unknown;
	setIsInvalidGeneratedContent: (value: boolean) => void;
	retryGeneration: () => void;
}) {
	const message =
		generationError instanceof GenerationRateLimitError
			? t('use_resourcegen_drawer.sharefile_requestlist.rate_limit_error.message')
			: generationError instanceof GenerationDeclinedError
			? t(
					'use_resourcegen_drawer.sharefile_requestlist.generation_declined_error.message'
			  )
			: t('use_resourcegen_drawer.sharefile_requestlist.generation_error.message');

	const description =
		generationError instanceof GenerationRateLimitError
			? t('use_resourcegen_drawer.sharefile_requestlist.rate_limit_error.description')
			: generationError instanceof GenerationDeclinedError
			? t(
					'use_resourcegen_drawer.sharefile_requestlist.generation_declined_error.description'
			  )
			: t('use_resourcegen_drawer.sharefile_requestlist.generation_error.description');

	return (
		<Alert
			message={message}
			description={description}
			type="error"
			icon={<WarningOctagonFilled />}
			showIcon
			action={
				<Space>
					<Button
						type="primary"
						danger
						size="large"
						onClick={() => {
							setIsInvalidGeneratedContent(false);
							retryGeneration();
						}}
					>
						{t('common.retry')}
					</Button>
				</Space>
			}
		/>
	);
}
