import { getApiBaseUrl } from '@data/helpers';
import { GeneratableResourceType, GenerationParams } from '@sdk';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { pollWithConstantBackOff } from '@utils/poller';
import axios from 'axios';
import { initializeClient, ResourceGenClient } from './client';
import {
	GenerationDeclinedError,
	GenerationFailedError,
	GenerationRateLimitError,
} from './errors';
import { StatusRecord, StatusState } from './types';

export class ResourceGenSdk {
	private initializedClient: ResourceGenClient;

	public Initialize(client: ResourceGenClient) {
		this.initializedClient = client;
	}

	/**
	 *
	 * @param resourceType
	 * @param params
	 * @returns
	 * @throws @see {AxiosError} if generateResource or polling HTTP operation itself fails (server returns 4xx or 5xx) that is not handled by the SDK
	 * @throws @see {GenerationRateLimitError} if the generation task is rate limited (server normal operation, but rate limit is exceeded)
	 * @throws @see {GenerationFailedError} if the generation task fails (server normal operation, but generation fails)
	 * @throws @see {GenerationDeclinedError} if the generation task is declined (server normal operation, but generation is declined by the AI due to misuse, harmful propmt, or an uncategorizable but "shady" input it doesn't wish to process)
	 */
	public async generateResource(
		resourceType: GeneratableResourceType,
		params: GenerationParams<GeneratableResourceType>,
		ac: AbortController = new AbortController()
	): Promise<StatusRecord> {
		try {
			const task = await this.initializedClient.generateResource(
				resourceType,
				params,
				ac
			);

			const result = await pollWithConstantBackOff<StatusRecord>({
				pollFn: async () => {
					return await this.initializedClient.getStatus(task.task_id, ac);
				},
				validateFn: (statusRecord: StatusRecord) => {
					if (
						statusRecord.state === StatusState.Failed ||
						statusRecord.state === StatusState.Unknown
					) {
						if (typeof statusRecord?.payload === 'string') {
							if (
								statusRecord.payload.includes('DECLINED_UNKNOWN') ||
								statusRecord.payload.includes('DECLINED_HARMFUL') ||
								statusRecord.payload.includes('DECLINED_MISUSE')
							) {
								throw new GenerationDeclinedError();
							}
						}
						throw new GenerationFailedError();
					}
					return statusRecord.state === StatusState.Completed;
				},
				delayInMilliSeconds: 1500,
				maxAttempts: 120,
			});
			return result;
		} catch (error) {
			if (axios.isAxiosError(error) && error?.response?.status === 429) {
				throw new GenerationRateLimitError();
			}
			throw error;
		}
	}
}

export const resourceGenSdk = new ResourceGenSdk();

export function configureResourceGenerationSdk(app: PiletApi) {
	resourceGenSdk.Initialize(
		initializeClient({
			baseUrl: getApiBaseUrl(app, 'resourcegen'),
		})
	);
}
