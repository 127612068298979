import { useState } from 'react';
import { Close } from '@sharefiledev/icons';
import { ModalProps } from 'antd';

export type ModalState = {
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type UseModalProvidedProps = ModalState & { modalProps: ModalProps };

export const useDefaultModal = ({
	overrideState,
	...configuration
}: DefaultModalConfiguration): UseModalProvidedProps => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	return {
		isModalOpen: overrideState?.isModalOpen ?? isModalOpen,
		setIsModalOpen: overrideState?.setIsModalOpen ?? setIsModalOpen,
		modalProps: getDefaultModalProps({
			state: overrideState
				? { ...overrideState }
				: {
						isModalOpen,
						setIsModalOpen,
				  },
			...configuration,
		}),
	};
};

export type DefaultModalConfiguration = {
	titleText?: string;
	onClose?(): void;
	overrideState?: ModalState;
};

export type ModalConfiguration<T = DefaultModalConfiguration> = T & {
	state: ModalState;
};

export const getDefaultModalProps = (
	props: ModalConfiguration<DefaultModalConfiguration>
): ModalProps => ({
	open: props.state.isModalOpen,
	title: props.titleText,
	closeIcon: <Close size={24} color="black" />,
	width: '95vw',
	onCancel: () => {
		props.onClose();
		props.state.setIsModalOpen(false);
	},
	footer: null,
	centered: true,
});
