import { PackageSpec } from '@sharefile/packaging-types';
import { EditableResource, PackageEditorState } from '../types/editorTypes';

export function recomputePackage(state: PackageEditorState): PackageSpec {
	if (!state.editablePackage) {
		return { ...state.originalPackage };
	}
	const selectedResources = state.editablePackage.resources.filter(
		(resource: EditableResource) => resource.selected
	);
	const newPackage: PackageSpec = {
		type: 'sharefile:package',
		data: {
			resources: selectedResources.map((resource: EditableResource) => {
				const { selected, resourceEditorState, ...res } = resource;
				return {
					...res,
					children: !!res.children ? recomputeChildren(res.children) : [],
				};
			}),
		},
	};
	return newPackage;
}

function recomputeChildren(childern: EditableResource[]) {
	// render only selected children in the computed package
	return childern
		.filter((child: EditableResource) => child.selected)
		.map((child: EditableResource) => {
			return {
				...child,
				children: !!child.children ? recomputeChildren(child.children) : [],
			};
		});
}
