// generated via yarn update:industry:strings
export const industryLocaleKeys = [
	{
		industry_key: 'industries.accounting.label',
		roles_keys: [
			'industries.accounting.roles.auditor',
			'industries.accounting.roles.tax_accountant',
			'industries.accounting.roles.financial_analyst',
			'industries.accounting.roles.chief_financial_officer__cfo_',
			'industries.accounting.roles.forensic_accountant',
			'industries.accounting.roles.accounts_payable_specialist',
			'industries.accounting.roles.accounts_receivable_specialist',
			'industries.accounting.roles.internal_auditor',
			'industries.accounting.roles.controller',
			'industries.accounting.roles.budget_analyst',
		],
	},
	{
		industry_key: 'industries.agriculture.label',
		roles_keys: [
			'industries.agriculture.roles.farm_manager',
			'industries.agriculture.roles.agronomist',
			'industries.agriculture.roles.agricultural_engineer',
			'industries.agriculture.roles.crop_scientist',
			'industries.agriculture.roles.livestock_manager',
			'industries.agriculture.roles.soil_scientist',
			'industries.agriculture.roles.agricultural_consultant',
			'industries.agriculture.roles.irrigation_specialist',
			'industries.agriculture.roles.agribusiness_manager',
			'industries.agriculture.roles.agricultural_technician',
		],
	},
	{
		industry_key: 'industries.apparel.label',
		roles_keys: [
			'industries.apparel.roles.fashion_designer',
			'industries.apparel.roles.merchandiser',
			'industries.apparel.roles.production_manager',
			'industries.apparel.roles.textile_technologist',
			'industries.apparel.roles.fashion_buyer',
			'industries.apparel.roles.pattern_maker',
			'industries.apparel.roles.apparel_product_developer',
			'industries.apparel.roles.fashion_illustrator',
			'industries.apparel.roles.quality_control_inspector',
			'industries.apparel.roles.fashion_marketing_specialist',
		],
	},
	{
		industry_key: 'industries.banking.label',
		roles_keys: [
			'industries.banking.roles.investment_banker',
			'industries.banking.roles.loan_officer',
			'industries.banking.roles.financial_advisor',
			'industries.banking.roles.branch_manager',
			'industries.banking.roles.risk_manager',
			'industries.banking.roles.credit_analyst',
			'industries.banking.roles.relationship_manager',
			'industries.banking.roles.compliance_officer',
			'industries.banking.roles.wealth_manager',
			'industries.banking.roles.treasury_analyst',
		],
	},
	{
		industry_key: 'industries.biotechnology.label',
		roles_keys: [
			'industries.biotechnology.roles.research_scientist',
			'industries.biotechnology.roles.bioprocess_engineer',
			'industries.biotechnology.roles.regulatory_affairs_specialist',
			'industries.biotechnology.roles.bioinformatics_specialist',
			'industries.biotechnology.roles.clinical_research_associate',
			'industries.biotechnology.roles.biotechnology_technician',
			'industries.biotechnology.roles.quality_assurance_specialist',
			'industries.biotechnology.roles.biomedical_engineer',
			'industries.biotechnology.roles.genetic_counselor',
			'industries.biotechnology.roles.biostatistician',
		],
	},
	{
		industry_key: 'industries.chemicals.label',
		roles_keys: [
			'industries.chemicals.roles.chemical_engineer',
			'industries.chemicals.roles.process_chemist',
			'industries.chemicals.roles.environmental_health_and_safety_specialist',
			'industries.chemicals.roles.quality_control_manager',
			'industries.chemicals.roles.production_supervisor',
			'industries.chemicals.roles.r_d_scientist',
			'industries.chemicals.roles.regulatory_compliance_specialist',
			'industries.chemicals.roles.analytical_chemist',
			'industries.chemicals.roles.chemical_plant_operator',
			'industries.chemicals.roles.formulation_chemist',
		],
	},
	{
		industry_key: 'industries.communications.label',
		roles_keys: [
			'industries.communications.roles.public_relations_specialist',
			'industries.communications.roles.marketing_manager',
			'industries.communications.roles.social_media_manager',
			'industries.communications.roles.corporate_communications_manager',
			'industries.communications.roles.content_strategist',
			'industries.communications.roles.communications_coordinator',
			'industries.communications.roles.media_relations_specialist',
			'industries.communications.roles.internal_communications_specialist',
			'industries.communications.roles.speechwriter',
			'industries.communications.roles.copywriter',
		],
	},
	{
		industry_key: 'industries.construction.label',
		roles_keys: [
			'industries.construction.roles.project_manager',
			'industries.construction.roles.civil_engineer',
			'industries.construction.roles.architect',
			'industries.construction.roles.construction_manager',
			'industries.construction.roles.quantity_surveyor',
			'industries.construction.roles.site_supervisor',
			'industries.construction.roles.structural_engineer',
			'industries.construction.roles.building_inspector',
			'industries.construction.roles.safety_manager',
			'industries.construction.roles.estimator',
		],
	},
	{
		industry_key: 'industries.consulting.label',
		roles_keys: [
			'industries.consulting.roles.management_consultant',
			'industries.consulting.roles.strategy_analyst',
			'industries.consulting.roles.it_consultant',
			'industries.consulting.roles.human_resources_consultant',
			'industries.consulting.roles.financial_consultant',
			'industries.consulting.roles.business_analyst',
			'industries.consulting.roles.operations_consultant',
			'industries.consulting.roles.marketing_consultant',
			'industries.consulting.roles.environmental_consultant',
			'industries.consulting.roles.legal_consultant',
		],
	},
	{
		industry_key: 'industries.e_commerce.label',
		roles_keys: [
			'industries.e_commerce.roles.e_commerce_manager',
			'industries.e_commerce.roles.digital_marketing_specialist',
			'industries.e_commerce.roles.customer_experience_manager',
			'industries.e_commerce.roles.marketplace_analyst',
			'industries.e_commerce.roles.supply_chain_manager',
			'industries.e_commerce.roles.product_manager',
			'industries.e_commerce.roles.seo_specialist',
			'industries.e_commerce.roles.web_developer',
			'industries.e_commerce.roles.data_analyst',
			'industries.e_commerce.roles.content_manager',
		],
	},
	{
		industry_key: 'industries.education.label',
		roles_keys: [
			'industries.education.roles.teacher___educator',
			'industries.education.roles.school_administrator',
			'industries.education.roles.curriculum_developer',
			'industries.education.roles.education_consultant',
			'industries.education.roles.instructional_designer',
			'industries.education.roles.academic_advisor',
			'industries.education.roles.educational_psychologist',
			'industries.education.roles.special_education_teacher',
			'industries.education.roles.school_counselor',
			'industries.education.roles.librarian',
		],
	},
	{
		industry_key: 'industries.electronics.label',
		roles_keys: [
			'industries.electronics.roles.electrical_engineer',
			'industries.electronics.roles.hardware_designer',
			'industries.electronics.roles.semiconductor_technician',
			'industries.electronics.roles.embedded_systems_engineer',
			'industries.electronics.roles.robotics_engineer',
			'industries.electronics.roles.electronics_technician',
			'industries.electronics.roles.pcb_designer',
			'industries.electronics.roles.systems_engineer',
			'industries.electronics.roles.quality_assurance_engineer',
			'industries.electronics.roles.product_development_engineer',
		],
	},
	{
		industry_key: 'industries.energy.label',
		roles_keys: [
			'industries.energy.roles.energy_analyst',
			'industries.energy.roles.power_plant_engineer',
			'industries.energy.roles.renewable_energy_specialist',
			'industries.energy.roles.energy_trader',
			'industries.energy.roles.environmental_specialist',
			'industries.energy.roles.energy_consultant',
			'industries.energy.roles.energy_manager',
			'industries.energy.roles.petroleum_engineer',
			'industries.energy.roles.solar_energy_technician',
			'industries.energy.roles.wind_turbine_technician',
		],
	},
	{
		industry_key: 'industries.engineering.label',
		roles_keys: [
			'industries.engineering.roles.mechanical_engineer',
			'industries.engineering.roles.civil_engineer',
			'industries.engineering.roles.aerospace_engineer',
			'industries.engineering.roles.chemical_engineer',
			'industries.engineering.roles.electrical_engineer',
			'industries.engineering.roles.industrial_engineer',
			'industries.engineering.roles.software_engineer',
			'industries.engineering.roles.structural_engineer',
			'industries.engineering.roles.environmental_engineer',
			'industries.engineering.roles.biomedical_engineer',
		],
	},
	{
		industry_key: 'industries.entertainment.label',
		roles_keys: [
			'industries.entertainment.roles.actor___actress',
			'industries.entertainment.roles.film_director',
			'industries.entertainment.roles.producer',
			'industries.entertainment.roles.screenwriter',
			'industries.entertainment.roles.entertainment_lawyer',
			'industries.entertainment.roles.casting_director',
			'industries.entertainment.roles.cinematographer',
			'industries.entertainment.roles.sound_engineer',
			'industries.entertainment.roles.production_designer',
			'industries.entertainment.roles.talent_agent',
		],
	},
	{
		industry_key: 'industries.environmental.label',
		roles_keys: [
			'industries.environmental.roles.environmental_scientist',
			'industries.environmental.roles.environmental_engineer',
			'industries.environmental.roles.sustainability_consultant',
			'industries.environmental.roles.ecologist',
			'industries.environmental.roles.environmental_compliance_manager',
			'industries.environmental.roles.conservation_scientist',
			'industries.environmental.roles.environmental_health_and_safety_specialist',
			'industries.environmental.roles.environmental_planner',
			'industries.environmental.roles.environmental_educator',
			'industries.environmental.roles.wildlife_biologist',
		],
	},
	{
		industry_key: 'industries.finance.label',
		roles_keys: [
			'industries.finance.roles.financial_analyst',
			'industries.finance.roles.investment_banker',
			'industries.finance.roles.portfolio_manager',
			'industries.finance.roles.financial_planner',
			'industries.finance.roles.risk_analyst',
			'industries.finance.roles.credit_analyst',
			'industries.finance.roles.compliance_officer',
			'industries.finance.roles.treasury_analyst',
			'industries.finance.roles.financial_controller',
			'industries.finance.roles.budget_analyst',
		],
	},
	{
		industry_key: 'industries.food___beverage.label',
		roles_keys: [
			'industries.food___beverage.roles.chef',
			'industries.food___beverage.roles.food_scientist',
			'industries.food___beverage.roles.nutritionist',
			'industries.food___beverage.roles.food_safety_manager',
			'industries.food___beverage.roles.restaurant_manager',
			'industries.food___beverage.roles.beverage_manager',
			'industries.food___beverage.roles.pastry_chef',
			'industries.food___beverage.roles.sommelier',
			'industries.food___beverage.roles.food_technologist',
			'industries.food___beverage.roles.quality_assurance_manager',
		],
	},
	{
		industry_key: 'industries.government.label',
		roles_keys: [
			'industries.government.roles.policy_analyst',
			'industries.government.roles.civil_servant',
			'industries.government.roles.diplomat',
			'industries.government.roles.government_relations_specialist',
			'industries.government.roles.legislative_assistant',
			'industries.government.roles.public_administrator',
			'industries.government.roles.city_planner',
			'industries.government.roles.budget_analyst',
			'industries.government.roles.intelligence_analyst',
			'industries.government.roles.public_affairs_specialist',
		],
	},
	{
		industry_key: 'industries.graphic_design.label',
		roles_keys: [
			'industries.graphic_design.roles.graphic_designer',
			'industries.graphic_design.roles.art_director',
			'industries.graphic_design.roles.illustrator',
			'industries.graphic_design.roles.ux_ui_designer',
			'industries.graphic_design.roles.branding_specialist',
			'industries.graphic_design.roles.creative_director',
			'industries.graphic_design.roles.web_designer',
			'industries.graphic_design.roles.motion_graphics_designer',
			'industries.graphic_design.roles.package_designer',
			'industries.graphic_design.roles.visual_designer',
		],
	},
	{
		industry_key: 'industries.healthcare.label',
		roles_keys: [
			'industries.healthcare.roles.physician',
			'industries.healthcare.roles.nurse_practitioner',
			'industries.healthcare.roles.healthcare_administrator',
			'industries.healthcare.roles.medical_researcher',
			'industries.healthcare.roles.pharmacist',
			'industries.healthcare.roles.medical_technologist',
			'industries.healthcare.roles.physical_therapist',
			'industries.healthcare.roles.occupational_therapist',
			'industries.healthcare.roles.registered_nurse',
			'industries.healthcare.roles.radiologic_technologist',
		],
	},
	{
		industry_key: 'industries.hospitality.label',
		roles_keys: [
			'industries.hospitality.roles.hotel_manager',
			'industries.hospitality.roles.event_planner',
			'industries.hospitality.roles.restaurant_manager',
			'industries.hospitality.roles.hospitality_consultant',
			'industries.hospitality.roles.concierge',
			'industries.hospitality.roles.front_desk_manager',
			'industries.hospitality.roles.housekeeping_manager',
			'industries.hospitality.roles.food_and_beverage_manager',
			'industries.hospitality.roles.banquet_manager',
			'industries.hospitality.roles.guest_services_manager',
		],
	},
	{
		industry_key: 'industries.insurance.label',
		roles_keys: [
			'industries.insurance.roles.actuary',
			'industries.insurance.roles.insurance_underwriter',
			'industries.insurance.roles.claims_adjuster',
			'industries.insurance.roles.risk_manager',
			'industries.insurance.roles.insurance_broker',
			'industries.insurance.roles.insurance_agent',
			'industries.insurance.roles.loss_control_specialist',
			'industries.insurance.roles.claims_examiner',
			'industries.insurance.roles.compliance_officer',
			'industries.insurance.roles.customer_service_representative',
		],
	},
	{
		industry_key: 'industries.legal.label',
		roles_keys: [
			'industries.legal.roles.lawyer',
			'industries.legal.roles.legal_secretary',
			'industries.legal.roles.paralegal',
			'industries.legal.roles.judge',
			'industries.legal.roles.legal_counsel',
			'industries.legal.roles.corporate_attorney',
			'industries.legal.roles.litigation_attorney',
			'industries.legal.roles.legal_assistant',
			'industries.legal.roles.compliance_officer',
			'industries.legal.roles.court_clerk',
		],
	},
	{
		industry_key: 'industries.machinery.label',
		roles_keys: [
			'industries.machinery.roles.mechanical_engineer',
			'industries.machinery.roles.maintenance_technician',
			'industries.machinery.roles.production_manager',
			'industries.machinery.roles.quality_control_engineer',
			'industries.machinery.roles.operations_manager',
			'industries.machinery.roles.cnc_machinist',
			'industries.machinery.roles.manufacturing_engineer',
			'industries.machinery.roles.field_service_technician',
			'industries.machinery.roles.tool_and_die_maker',
			'industries.machinery.roles.equipment_operator',
		],
	},
	{
		industry_key: 'industries.manufacturing.label',
		roles_keys: [
			'industries.manufacturing.roles.production_manager',
			'industries.manufacturing.roles.quality_assurance_manager',
			'industries.manufacturing.roles.supply_chain_manager',
			'industries.manufacturing.roles.industrial_engineer',
			'industries.manufacturing.roles.manufacturing_engineer',
			'industries.manufacturing.roles.operations_manager',
			'industries.manufacturing.roles.maintenance_manager',
			'industries.manufacturing.roles.process_engineer',
			'industries.manufacturing.roles.plant_manager',
			'industries.manufacturing.roles.production_planner',
		],
	},
	{
		industry_key: 'industries.marketing.label',
		roles_keys: [
			'industries.marketing.roles.marketing_manager',
			'industries.marketing.roles.digital_marketing_specialist',
			'industries.marketing.roles.market_research_analyst',
			'industries.marketing.roles.brand_manager',
			'industries.marketing.roles.public_relations_specialist',
			'industries.marketing.roles.social_media_manager',
			'industries.marketing.roles.content_marketing_specialist',
			'industries.marketing.roles.marketing_coordinator',
			'industries.marketing.roles.seo_specialist',
			'industries.marketing.roles.email_marketing_specialist',
		],
	},
	{
		industry_key: 'industries.media.label',
		roles_keys: [
			'industries.media.roles.journalist',
			'industries.media.roles.editor',
			'industries.media.roles.tv_producer',
			'industries.media.roles.media_buyer',
			'industries.media.roles.multimedia_artist',
			'industries.media.roles.broadcast_technician',
			'industries.media.roles.news_anchor',
			'industries.media.roles.copywriter',
			'industries.media.roles.video_editor',
			'industries.media.roles.social_media_manager',
		],
	},
	{
		industry_key: 'industries.mining.label',
		roles_keys: [
			'industries.mining.roles.geologist',
			'industries.mining.roles.mining_engineer',
			'industries.mining.roles.environmental_specialist__mining_',
			'industries.mining.roles.safety_manager__mining_',
			'industries.mining.roles.mine_operations_manager',
			'industries.mining.roles.drilling_engineer',
			'industries.mining.roles.metallurgist',
			'industries.mining.roles.mine_surveyor',
			'industries.mining.roles.mineral_processing_engineer',
			'industries.mining.roles.maintenance_planner',
		],
	},
	{
		industry_key: 'industries.not_for_profit.label',
		roles_keys: [
			'industries.not_for_profit.roles.program_manager',
			'industries.not_for_profit.roles.fundraiser',
			'industries.not_for_profit.roles.advocacy_coordinator',
			'industries.not_for_profit.roles.grant_writer',
			'industries.not_for_profit.roles.volunteer_coordinator',
			'industries.not_for_profit.roles.community_outreach_manager',
			'industries.not_for_profit.roles.development_director',
			'industries.not_for_profit.roles.nonprofit_administrator',
			'industries.not_for_profit.roles.social_worker',
			'industries.not_for_profit.roles.communications_director',
		],
	},
	{
		industry_key: 'industries.pharmaceuticals.label',
		roles_keys: [
			'industries.pharmaceuticals.roles.pharmaceutical_sales_representative',
			'industries.pharmaceuticals.roles.research_scientist',
			'industries.pharmaceuticals.roles.regulatory_affairs_specialist',
			'industries.pharmaceuticals.roles.clinical_trial_manager',
			'industries.pharmaceuticals.roles.pharmacologist',
			'industries.pharmaceuticals.roles.quality_control_analyst',
			'industries.pharmaceuticals.roles.formulation_scientist',
			'industries.pharmaceuticals.roles.medical_science_liaison',
			'industries.pharmaceuticals.roles.drug_safety_specialist',
			'industries.pharmaceuticals.roles.biostatistician',
		],
	},
	{
		industry_key: 'industries.real_estate.label',
		roles_keys: [
			'industries.real_estate.roles.real_estate_agent',
			'industries.real_estate.roles.property_manager',
			'industries.real_estate.roles.real_estate_developer',
			'industries.real_estate.roles.appraiser',
			'industries.real_estate.roles.leasing_consultant',
			'industries.real_estate.roles.real_estate_broker',
			'industries.real_estate.roles.commercial_real_estate_agent',
			'industries.real_estate.roles.real_estate_analyst',
			'industries.real_estate.roles.real_estate_marketing_specialist',
			'industries.real_estate.roles.title_examiner',
		],
	},
	{
		industry_key: 'industries.recreation.label',
		roles_keys: [
			'industries.recreation.roles.recreation_director',
			'industries.recreation.roles.event_coordinator',
			'industries.recreation.roles.park_ranger',
			'industries.recreation.roles.fitness_instructor',
			'industries.recreation.roles.sports_coach',
			'industries.recreation.roles.recreation_therapist',
			'industries.recreation.roles.camp_director',
			'industries.recreation.roles.outdoor_education_instructor',
			'industries.recreation.roles.aquatics_director',
			'industries.recreation.roles.adventure_guide',
		],
	},
	{
		industry_key: 'industries.retail.label',
		roles_keys: [
			'industries.retail.roles.store_manager',
			'industries.retail.roles.sales_associate',
			'industries.retail.roles.merchandiser',
			'industries.retail.roles.retail_buyer',
			'industries.retail.roles.visual_merchandiser',
			'industries.retail.roles.inventory_manager',
			'industries.retail.roles.customer_service_representative',
			'industries.retail.roles.cashier',
			'industries.retail.roles.retail_manager',
			'industries.retail.roles.loss_prevention_specialist',
		],
	},
	{
		industry_key: 'industries.shipping.label',
		roles_keys: [
			'industries.shipping.roles.logistics_manager',
			'industries.shipping.roles.shipping_coordinator',
			'industries.shipping.roles.port_operations_manager',
			'industries.shipping.roles.freight_forwarder',
			'industries.shipping.roles.customs_broker',
			'industries.shipping.roles.shipping_clerk',
			'industries.shipping.roles.marine_engineer',
			'industries.shipping.roles.cargo_inspector',
			'industries.shipping.roles.supply_chain_analyst',
			'industries.shipping.roles.deck_officer',
		],
	},
	{
		industry_key: 'industries.technology.label',
		roles_keys: [
			'industries.technology.roles.software_engineer',
			'industries.technology.roles.data_scientist',
			'industries.technology.roles.systems_analyst',
			'industries.technology.roles.it_project_manager',
			'industries.technology.roles.network_administrator',
			'industries.technology.roles.cybersecurity_analyst',
			'industries.technology.roles.devops_engineer',
			'industries.technology.roles.product_manager',
			'industries.technology.roles.technical_support_specialist',
			'industries.technology.roles.cloud_solutions_architect',
		],
	},
	{
		industry_key: 'industries.telecommunications.label',
		roles_keys: [
			'industries.telecommunications.roles.network_engineer',
			'industries.telecommunications.roles.telecommunications_technician',
			'industries.telecommunications.roles.systems_administrator',
			'industries.telecommunications.roles.rf_engineer',
			'industries.telecommunications.roles.telecom_analyst',
			'industries.telecommunications.roles.customer_support_specialist',
			'industries.telecommunications.roles.sales_engineer',
			'industries.telecommunications.roles.telecommunications_manager',
			'industries.telecommunications.roles.voip_engineer',
			'industries.telecommunications.roles.field_service_technician',
		],
	},
	{
		industry_key: 'industries.transportation.label',
		roles_keys: [
			'industries.transportation.roles.transportation_manager',
			'industries.transportation.roles.fleet_manager',
			'industries.transportation.roles.logistics_coordinator',
			'industries.transportation.roles.traffic_analyst',
			'industries.transportation.roles.transportation_planner',
			'industries.transportation.roles.dispatch_supervisor',
			'industries.transportation.roles.supply_chain_manager',
			'industries.transportation.roles.safety_coordinator',
			'industries.transportation.roles.public_transit_manager',
			'industries.transportation.roles.vehicle_maintenance_manager',
		],
	},
	{
		industry_key: 'industries.utilities.label',
		roles_keys: [
			'industries.utilities.roles.utility_manager',
			'industries.utilities.roles.electrical_engineer',
			'industries.utilities.roles.water_treatment_specialist',
			'industries.utilities.roles.utility_analyst',
			'industries.utilities.roles.energy_efficiency_consultant',
			'industries.utilities.roles.environmental_compliance_specialist',
			'industries.utilities.roles.utility_field_technician',
			'industries.utilities.roles.gas_controller',
			'industries.utilities.roles.operations_manager',
			'industries.utilities.roles.utility_planner',
		],
	},
];
