export type StatusRecord = {
	id: string;
	state: StatusState;
	version: StatusVersion;
	accountId: string;
	userRid: string;
	created: number;
	updated: number;
	payload?: any;
};

export type GenerateResourceResponse = {
	task_id: string;
};

export enum StatusState {
	Unknown = 'unknown',
	Received = 'received',
	Processing = 'processing',
	Completed = 'completed',
	Failed = 'failed',
}

export enum StatusVersion {
	UnknownVersion = 'unknown',
	Version1 = 'v1',
}

export type StatusCreateRequest = {
	state: StatusState;
	payload: any;
};

export type StatusCreateResponse = {
	id: string;
	location: string;
};

export type StatusUpdateRequest = {
	id: string;
	state: string;
	payload: any;
};

export function toStatusString(state: StatusState): string {
	switch (state) {
		case StatusState.Received:
			return 'Received';
		case StatusState.Processing:
			return 'Processing';
		case StatusState.Completed:
			return 'Completed';
		case StatusState.Failed:
			return 'Failed';
		default:
			return 'Unknown';
	}
}

export function fromStatusString(state: string): StatusState {
	switch (state?.toLowerCase()) {
		case 'received':
			return StatusState.Received;
		case 'processing':
			return StatusState.Processing;
		case 'completed':
			return StatusState.Completed;
		case 'failed':
			return StatusState.Failed;
		default:
			return StatusState.Unknown;
	}
}

export function toStatusVersionString(version: StatusVersion): string {
	switch (version) {
		case StatusVersion.Version1:
			return 'Version1';
		default:
			return 'UnknownVersion';
	}
}

export function fromStatusVersionString(version: string): StatusVersion {
	switch (version?.toLowerCase()) {
		case 'v1':
			return StatusVersion.Version1;
		default:
			return StatusVersion.UnknownVersion;
	}
}
