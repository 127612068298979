import React from 'react';
import { GeneratableResourceType, UseResourceGenerationOverlayParams } from '@sdk';
import { ResourceGenContextProvider } from '@utils/context/useResourceGenerationContext';
import { Drawer } from 'antd';

export interface ResourceGenDrawerProps extends UseResourceGenerationOverlayParams {
	// The title of the drawer.
	title?: string;
	// The function to call when the drawer is closed.
	onClose?(): void;
	// The content of the drawer.
	children: React.ReactNode;
	// The open/close state of the drawer.
	isOpen: boolean;
	// The width of the drawer.
	size?: 'default' | 'large';
	// The placement of the drawer.
	placement?: 'left' | 'right' | 'top' | 'bottom';
	// The function to call when the isOpen state has changed.
	onOpenChange?(open: boolean): void;
	// The function to call when a navigation event occurs.
	onNavigate?(resourceId: string): void;
}

export const ResourceGenDrawer: React.FC<ResourceGenDrawerProps> = ({
	title,
	onClose,
	children,
	isOpen,
	size = 'default',
	placement = 'right',
	onOpenChange = (_open: boolean) => {},
	onNavigate = (_resourceId: string) => {},
	resourceType,
	containerRID,
	generationFormDefaults,
}: ResourceGenDrawerProps) => {
	return (
		<Drawer
			title={title}
			open={isOpen}
			onClose={onClose}
			size={size}
			placement={placement}
			afterOpenChange={onOpenChange}
			width={'38%'}
			destroyOnClose
		>
			<ResourceGenContextProvider
				onNavigate={onNavigate}
				onClose={onClose}
				resourceType={resourceType as GeneratableResourceType}
				containerRID={containerRID}
				generationFormDefaults={generationFormDefaults}
			>
				{children}
			</ResourceGenContextProvider>
		</Drawer>
	);
};
